export function hasEitherPerm(permissionsArray, optionsArray) {
    if(!Array.isArray(permissionsArray) || !Array.isArray(optionsArray)) {
        throw new Error("Bad permisssion parameters!");
    }

    let Validated = false;
            
    let i = 0;
    while(i < optionsArray.length && !Validated) {
        if(permissionsArray.includes(optionsArray[i])) {
            Validated = true;
        }
        i++;
    }

    return Validated;
}

export function hasPerm(permissionsArray, permissionString) {
    if(!Array.isArray(permissionsArray)) {
        throw new Error("Bad permisssion parameter!");
    }

    return permissionsArray.includes(permissionString);
}