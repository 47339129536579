import React, { Component } from "react";
import axios from "axios";
import { Form, Field } from 'simple-react-form'
import SimpleReactValidator from 'simple-react-validator';

import {
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Input,
    FormGroup, Label, Col,
} from "reactstrap";

export default class AddressCreator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,
            formState: {
                street: null,
                streetNo: null,
                other: null,
                city: null,
                county: null,
                postalCode: null,
            },
        }

        this.save = () => {
            if (!this.validator.allValid()) {
                this.validator.showMessages();
                // rerender to show messages for the first time
                this.forceUpdate();

                return;
            }

            this.setState({loading: true});

            this.saveAddress()
                .then(addressID => {
                    if (this.props.onSave) {
                        this.props.onSave(addressID);
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({error: true});
                })
                .then(() => {
                    this.setState({loading: false}, this.close());
                });
    }

        this.close = () => {
            if (this.props.toggle && this.props.isOpen) {
                this.props.toggle();
            }
        }

        this.validator = new SimpleReactValidator({
            element: (message) => <div className='invalid-feedback' style={{ display: "initial" }}>{message}</div>
        });
    }

    async saveAddress() {
        let {formState} = this.state;
        let AddressData = new FormData();
        AddressData.append("street", formState.street);
        AddressData.append("street_no", formState.streetNo);
        if(formState.other) {
            AddressData.append("other", formState.other);
        }
        AddressData.append("city", formState.city);
        AddressData.append("county", formState.county);
        AddressData.append("postal_code", formState.postalCode);
    
        let registerResponse = await axios({
          method: "post",
          url: "address/create",
          data: AddressData,
          config: { headers: { "Content-Type": "multipart/form-data" }}
        });

        return registerResponse.data.id;
    }

    textInput(props) {
        return (
            <Input defaultValue={props.value} type={props.contentType} onChange={event => props.onChange(event.target.value)} placeholder={props.placeholder} invalid={props.invalid} />
        );
    }

    render() {
        return (
            <Modal fade={false} isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
                <ModalHeader toggle={this.props.toggle}>Adaugă o adresă nouă</ModalHeader>
                <ModalBody>
                    <Form state={this.state.formState} onChange={state => this.setState({ formState: state })}>
                        <FormGroup row>
                            <Label sm={2}>Strada</Label>
                            <Col sm={4}>
                                <Field contentType="text" fieldName="street" type={this.textInput} placeholder="ex. Bulevardul Unirii" />
                                {this.validator.message('street', this.state.formState.street, 'required')}
                            </Col>
                            <Label sm={2}>Numărul</Label>
                            <Col sm={4}>
                                <Field contentType="text" fieldName="streetNo" type={this.textInput} placeholder="ex. 123" />
                                {this.validator.message('streetNo', this.state.formState.streetNo, 'required')}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Alte informații</Label>
                            <Col sm={10}>
                                <Field contentType="text" fieldName="other" type={this.textInput} placeholder="ex. clădire, etaj, scară, apartament" />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Oraș</Label>
                            <Col sm={10}>
                                <Field contentType="text" fieldName="city" type={this.textInput} placeholder="ex. Ploiești" />
                                {this.validator.message('city', this.state.formState.city, 'required')}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Județ / Sector</Label>
                            <Col sm={10}>
                                <Field contentType="text" fieldName="county" type={this.textInput} placeholder="ex. Prahova" />
                                {this.validator.message('county', this.state.formState.county, 'required')}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Cod Poștal</Label>
                            <Col sm={10}>
                                <Field contentType="text" fieldName="postalCode" type={this.textInput} placeholder="ex. 100078" />
                                {this.validator.message('postalCode', this.state.formState.postalCode, 'required|numeric')}
                            </Col>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.close}>Închide</Button>
                    <Button color="primary" onClick={this.save}>Salvează</Button>
                </ModalFooter>
            </Modal >
        )
    }
}