import React, { Component } from "react";
import { Table, Button, ButtonGroup } from "reactstrap";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserEditor from "../../components/userEditor";
import UserCreator from "../../components/userCreator";

export default class UsersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      userData: [],
      currentlyEditing: null,
      editorOpen: false,
      creatorOpen: false,
    };
  }

  toggleEditor = () => {
    this.setState({editorOpen: !this.state.editorOpen});
  }

  toggleCreator = () => {
    this.setState({creatorOpen: !this.state.creatorOpen});
  }

  initializeEditor = userData => {
    this.setState({currentlyEditing: userData},
      this.toggleEditor
    );
  }

  destroyEditor = () => {
    this.setState({currentlyEditing: null});
  }

  handleEditorSave = () => {
    this.getUsers()
      .then(() => this.setState({isLoaded: true}))
      .catch(error => console.log(error));
  }

  async getUsers() {
    let response = await Axios.get('/admin/get_seller_users');

    this.setState({
      userData: response.data.user_data
    });
  }

  componentDidMount() {
    this.getUsers()
      .then(() => this.setState({isLoaded: true}))
      .catch(error => console.log(error));
  }

  render() {
    let {isLoaded, userData, currentlyEditing} = this.state;

    if(!isLoaded) {
      return (
        <div>Încărcare...</div>
      );
    }

    return (
      <div>
        <div className="d-flex justify-content-between">
          <h1>Utilizatori</h1>
          <Button color="success" onClick={this.toggleCreator}>Utilizator nou</Button>
        </div>
        <UserCreator isOpen={this.state.creatorOpen} toggle={this.toggleCreator} onSave={this.handleEditorSave} />
        <p>Mai jos puteți administra utilizatorii care folosesc platforma dvs.</p>
        <Table bordered striped size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Nume utilizator</th>
              <th>E-mail</th>
              <th>Telefon</th>
              <th>Denumire companie</th>
              <th>Editare</th>
            </tr>
          </thead>
          <tbody>
            {
              userData.map((user, i) => (
                <tr key={i}>
                  <th scope="row">{i+1}</th>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>{user.company.name}</td>
                  <td><Button size="sm" color="info" onClick={() => this.initializeEditor(user)}>Editare</Button></td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        {currentlyEditing && (
          <UserEditor isOpen={this.state.editorOpen} toggle={this.toggleEditor} onClosed={this.destroyEditor} onSave={this.handleEditorSave} {...currentlyEditing} />
        )}
      </div>
    );
  }
}
