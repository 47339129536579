import React, { Component } from "react";
import AddressPicker from "../../components/addressPicker";

import {Form, Field} from 'simple-react-form'
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';

import {
  Input,
  Alert,
} from "reactstrap";


export default class PasswordChangePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formState: {},

      confirmationPending: null,

      isLoading: true,
      success: undefined,
      error: undefined,
    };

    this.validator = new SimpleReactValidator({
      element: (message) => <div className='invalid-feedback' style={{display: "initial"}}>{message}</div>,
      validators: {
        matches: {  // name the rule
          message: 'The :attribute match the :field_to_match.',
          rule: (val, params) => {
            return val === this.state.formState[params[0]];
          },
          messageReplace: (message, params) => {
            if(!params[1]) {
              return message;
            }

            return message.replace(':field_to_match', params[1]);
          },
          required: true  // optional
        }
      },
    });

    this.submitCode = (event) => {
      event.preventDefault();
      
      if (this.validator.allValid()) {
        this.setState({isLoading: true});
      
        let Data = {
          code: this.state.formState.code,
        };
        
        axios.post('user/solve_password_change_request', Data)
          .then(() => {
            this.setState({
              isLoading: false,
              success: true,
              error: undefined,
            });
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
              error: true,
            });
            console.log(error)
          });
      } else {
        this.validator.showMessages();
        // rerender to show messages for the first time
        this.forceUpdate();
      }
    };
    this.submitRequest = (event) => {
      event.preventDefault();
      
      if (this.validator.allValid()) {
        this.setState({isLoading: true});

        let {formState} = this.state;
        
        let Data = {
          old_password: formState.old_password,
          new_password: formState.new_password,
        };
        
        axios.post('user/request_password_change', Data)
          .then(() => {
            this.getInfo()
              .then(() => {
                this.setState({
                  isLoading: false,
                  error: false,
                });
              }) 
              .catch(error => {
                this.setState({
                  isLoading: false,
                  error: true,
                });
                console.log(error)
              });
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
              error: true,
            });
            console.log(error)
          });
      } else {
        this.validator.showMessages();
        // rerender to show messages for the first time
        this.forceUpdate();
      }
    };
  }

  textInput(props) {
    return (
        <Input defaultValue={props.value}  type={props.contentType} onChange={event => props.onChange(event.target.value)} placeholder={props.placeholder} invalid={props.invalid}/>
    );
  }

  async getInfo() {
    let sellerInfo = await axios.get('user/password_change_request_pending');
    let {data} = sellerInfo;

    this.setState({confirmationPending: data.is_pending});
  }

  componentDidMount() {
    this.getInfo()
      .then(() => {
        this.setState({isLoading: false});
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    if(this.state.isLoading) {
      return (
        <h1>Încărcare...</h1>
      );
    }

    let content;
    
    if(this.state.success) {
      content = null;
    } else if(this.state.confirmationPending) {
      content = (
        <Form state={this.state.formState} onChange={state => this.setState({formState: state})}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Introdu codul de verificare</label>
              <Field contentType="text" fieldName="code" type={this.textInput} placeholder="Cod de verificare" />
              {this.validator.message('code', this.state.formState.code, 'required|string')}
            </div>
          </div>
          <button type="submit" className="btn btn-success" onClick={this.submitCode}>Finalizare</button>
        </Form>
      );
    } else {
      content = (
        <Form state={this.state.formState} onChange={state => this.setState({formState: state})}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Parola veche</label>
              <Field contentType="password" fieldName="old_password" type={this.textInput} placeholder="Parola veche" />
              {this.validator.message('old_password', this.state.formState.old_password, 'required|string')}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Parola nouă</label>
              <Field contentType="password" fieldName="new_password" type={this.textInput} placeholder="Parola nouă" />
              {this.validator.message('new_password', this.state.formState.new_password, 'required|string|min:8')}
            </div>
            <div className="form-group col-md-6">
              <label>Repetă parola nouă</label>
              <Field contentType="password" fieldName="new_password_check" type={this.textInput} placeholder="Parola nouă" />
              {this.validator.message('new_password_check', this.state.formState.new_password_check, ['required', {matches: ['new_password', 'new password']}])}
            </div>
          </div>
          <p>Un cod de verificare îți va fi trimis prin SMS.</p>
          <button type="submit" className="btn btn-success" onClick={this.submitRequest}>Schimbă parola</button>
        </Form>
      );
    }

    return (
      <div>
        <h1>Schimbare parolă</h1>
        <p>Schimbă parola asociată acestui cont</p>

        { 
          this.state.success && (
            <Alert color="success">Parola a fost actualizată cu succes!</Alert>
          )
        }

        { 
          this.state.error && (
            <Alert color="danger">O eroare a fost întâmpinată! Verificați datele introduse și reîncercați.</Alert>
          )
        }

        {content}
      </div>
    );
  }
}
