import React, { Component } from "react";
import axios from "axios";
import { Form, Field } from 'simple-react-form'
import SimpleReactValidator from 'simple-react-validator';

import {
    Alert,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Badge,
    Input,
    FormGroup, Label, Col,
} from "reactstrap";

import 'react-widgets/dist/css/react-widgets.css';
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

Moment.locale('en');
momentLocalizer();


export default class OfferCreator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,
            saved: false,

            formState: {},
        };

        this.validator = new SimpleReactValidator({
            element: (message) => (<div className='invalid-feedback' style={{ display: "initial" }}>{message}</div>),
            validators: {
                numberBetween: {  // name the rule
                    message: 'The :attribute must be a number between :min and :max.',
                    rule: (val, params, validator) => {
                        if (isNaN(val))
                            return false;

                        let min = parseFloat(params[0]);
                        let max = parseFloat(params[1]);
                        
                        return (val >= min && val <= max);
                    },
                    messageReplace: (message, params) => {
                        return message.replace(':min', params[0]).replace(':max', params[1]);
                    },
                    required: true  // optional
                }
            },
        });

        this.OnSubmit = (event) => {
            event.preventDefault();

            if (this.validator.allValid()) {
                this.update();
            } else {
                this.validator.showMessages();
                // rerender to show messages for the first time
                this.forceUpdate();
            }
        };

        this.update = () => {
            this.setState({ loading: true }, () => {
                this.createOffer()
                    .then((response) => {
                        console.log(response);
                        if (this.props.onUpdate) {
                            this.props.onUpdate();
                        }
                        this.setState({
                            loading: false,
                            saved: true,
                            savedResponse: response,
                        });
                    })
                    .catch((error) => {
                        this.setState({ loading: false, error: true });
                        console.log(error);
                    });
            });
        }

        this.close = () => {
            if(this.props.toggle) {
                this.props.toggle();
            }
        }
    }

    async createOffer() {
        let createResponse = await axios.get('offer/create', {
            params: {
                name: this.state.formState.name,
                start_date: this.state.formState.start_date.getTime() / 1000,
                end_date: this.state.formState.end_date.getTime() / 1000,
                description: this.state.formState.description,
                discount_percentage: this.state.formState.discount_percentage,
                product_data: this.state.formState.products,
            }
        })

        return createResponse.data;
    }

    textInput(props) {
        return (
            <Input defaultValue={props.value} type={props.contentType} onChange={event => props.onChange(event.target.value)} placeholder={props.placeholder} invalid={props.invalid} rows={props.rows} />
        );
    }

    render() {
        if(this.state.saved) {
            return (
                <Modal fade={false} isOpen={this.props.isOpen} toggle={this.props.toggle}>
                    <ModalHeader toggle={this.props.toggle}>Adaugă ofertă nouă</ModalHeader>
                    <ModalBody>
                        <h1>Succes!</h1>
                        <p>{this.state.savedResponse.created_data.inserted} produse au fost adăugate în ofertă!</p>
                        {
                            this.state.savedResponse.created_data.not_found && (
                                <div>
                                    <p>Următoarele coduri nu au fost găsite:</p>
                                    <ul>
                                        {this.state.savedResponse.created_data.not_found.map((value, index) => {
                                            return <li key={index}>{value}</li>;
                                        })}
                                    </ul>
                                </div>
                            )
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.close}>Închide</Button>
                    </ModalFooter>
                </Modal>
            );
        }
        return (
            <Modal fade={false} isOpen={this.props.isOpen} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>Adaugă ofertă nouă</ModalHeader>
                {this.state.loading ? (
                    <ModalBody>
                        <p>Salvare...</p>
                    </ModalBody>
                ) : (
                        <div>
                            <ModalBody>
                                {this.state.error && (
                                    <Alert color="danger">O eroare a fost întâmpinată la salvare!</Alert>
                                )}
                                <Form state={this.state.formState} onChange={state => this.setState({ formState: state })}>
                                    <h4>Nume</h4>
                                    <Field contentType="text" fieldName="name" type={this.textInput} placeholder="Nume ofertă" />
                                    {this.validator.message('name', this.state.formState.name, 'required')}

                                    <h4 className="mt-2">Data de început</h4>
                                    <Field defaultValue={null} fieldName="start_date" type={DateTimePicker} placeholder="Data de început a campaniei"/>
                                    {this.validator.message('start_date', this.state.formState.start_date, 'required')}

                                    <h4 className="mt-2">Data de sfârșit</h4>
                                    <Field defaultValue={null} fieldName="end_date" type={DateTimePicker} placeholder="Data de sfârșit a campaniei"/>
                                    {this.validator.message('end_date', this.state.formState.end_date, 'required')}

                                    <h4 className="mt-2">Descriere</h4>
                                    <Field contentType="textarea" fieldName="description" type={this.textInput} rows={5} />

                                    <h4 className="mt-2">Procent reducere</h4>
                                    <Field contentType="number" fieldName="discount_percentage" type={this.textInput} placeholder="Procent de reducere"/>
                                    {this.validator.message('discount_percentage', this.state.formState.discount_percentage, 'required|numberBetween:-100,0')}

                                    <h4 className="mt-2">Produse</h4>
                                    <p>Introduceți codurile noi ale produselor ce doriți să fie introduse în ofertă, câte unul pe linie.</p>
                                    <Field contentType="textarea" fieldName="products" type={this.textInput} rows={10} />
                                    {this.validator.message('products', this.state.formState.products, 'required')}
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={this.close}>Închide</Button>
                                <Button color="success" onClick={this.OnSubmit}>Salvează</Button>
                            </ModalFooter>
                        </div>
                    )}
            </Modal>
        )
    }
}