import React, { Component } from "react";
import axios from "axios";
import { Form, Field } from 'simple-react-form'
import SimpleReactValidator from 'simple-react-validator';

import {
    Alert,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Badge,
    Input,
    FormGroup, Label, Col,
} from "reactstrap";

export default class OrderStatusEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,
            mentions: null,
        };

        this.update = () => {
            this.setState({loading: true}, () => {
                this.updateStatus()
                    .then(() => {
                        if(this.props.toggle) {
                            this.props.toggle();
                        }
                        if(this.props.onUpdate) {
                            this.props.onUpdate();
                        }
                    })
                    .catch((error) => {
                        this.setState({loading: false, error: true});
                        console.log(error);
                    });
            });
        };

        this.close = () => {
            if(this.props.toggle) {
                this.props.toggle();
            }
        }
    }

    async updateStatus() {
        let updateResponse = await axios.get('order/update_status', {
          params: {
            order_id: this.props.orderID,
            new_status: this.props.newStatus,
            mentions: this.state.mentions,
          }
        })

        return updateResponse.data;
    }
    
    Status(orderStatus) {
        if (orderStatus === "PLACED") {
            return <Badge color="info">Neprocesată</Badge>;
        }
        if (orderStatus === "PROCESSING") {
            return <Badge color="warning">În lucru</Badge>;
        }
        if (orderStatus === "EXPEDITED") {
            return <Badge color="success">Expediată</Badge>;
        }
        if (orderStatus === "CANCELLED") {
            return <Badge color="danger">Anulată</Badge>;
        }
          
        return <Badge>Necunoscută</Badge>;
    }
    
    render() {
        return (
            <Modal fade={false} isOpen={this.props.isOpen} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>Schimbă starea comenzii</ModalHeader>
                {this.state.loading ? (
                    <ModalBody>
                        <p>Salvare...</p>
                    </ModalBody>
                ) : (
                    <div>
                        <ModalBody>
                            {this.state.error && (
                                <Alert color="danger">O eroare a fost întâmpinată la salvare!</Alert>
                            )}
                            <p>Starea comenzii #{this.props.orderID} va fi schimbată din {this.Status(this.props.oldStatus)} în {this.Status(this.props.newStatus)}</p>
                            <h4>Observații</h4>
                            <Input type="textarea" rows={10} onChange={event => this.setState({mentions: event.target.value})} />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.close}>Închide</Button>
                            <Button color="primary" onClick={this.update}>Actualizează</Button>
                        </ModalFooter>
                    </div>
                )}
            </Modal>
        )
    }
}