import React, { Component } from "react";
import { Switch, NavLink } from "react-router-dom";
import PrivateRoute from "../../components/privateRoute";
import * as perm from "../../helpers/permissionsHelper";


import {
  ListGroup,
  Badge,
  Row, Col
} from "reactstrap";

import pages from "../main";
import appContext from "../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class IndexPage extends Component {
  render() {
    let Menu;
    if(perm.hasPerm(this.context.userPermissions, "COMPANY_ADMIN")) {
      Menu = (
        <div>
          <ListGroup>
            <NavLink to="/admin" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
              <span><FontAwesomeIcon icon="home" className="mr-2" />Acasă</span>
            </NavLink>
          </ListGroup>

          <h5 className="mt-2 mb-2">Întreprindere</h5>
          <ListGroup>
            <NavLink to="/admin/orders" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
              <span><FontAwesomeIcon icon="truck" className="mr-2" />Comenzi</span>
            </NavLink>
            {/*<NavLink to="/admin/employees" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
              Angajați
      </NavLink>*/}
            <NavLink to="/admin/company-info" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
              <span><FontAwesomeIcon icon="address-card" className="mr-2" />Informații de contact</span>
            </NavLink>
            <NavLink to="/admin/offer-creator" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
              <span><FontAwesomeIcon icon="paperclip" className="mr-2" />Zonă de ofertare</span>
            </NavLink>
          </ListGroup>

          <h5 className="mt-2 mb-2">Parteneri</h5>
          <ListGroup>
            <NavLink to="/admin/offers" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
            <span><FontAwesomeIcon icon="percent" className="mr-2" />Administrare oferte</span>
            </NavLink>
            <NavLink to="/admin/tiers" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
              <span><FontAwesomeIcon icon="money-bill-wave" className="mr-2" />Ajustări de preț</span>
            </NavLink>
            <NavLink to="/admin/partners" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
              <span><FontAwesomeIcon icon="city" className="mr-2" />Vezi partenerii</span>
            </NavLink>
            <NavLink to="/admin/users" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
              <span><FontAwesomeIcon icon="users" className="mr-2" />Vezi utilizatorii</span>
            </NavLink>
          </ListGroup>

          <h5 className="mt-2 mb-2">Setări</h5>
          <ListGroup>
            <NavLink to="/admin/sms-settings" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
              <span><FontAwesomeIcon icon="comment" className="mr-2" />Setări SMS</span>
            </NavLink>
          </ListGroup>

          {/*
          <h5 className="mt-2 mb-2">Parteneri</h5>
          <ListGroup>
            
            <NavLink to="/admin/reports" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
              Rapoarte
            </NavLink>
          </ListGroup>
          */}
        </div>
      );
    } else {
      Menu = (
        <div>
          <ListGroup>
              <NavLink to="/admin" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                Acasă <Badge pill>14</Badge>
              </NavLink>
            </ListGroup>

            <h5 className="mt-2 mb-2">Întreprindere</h5>
            <ListGroup>
              <NavLink to="/admin/orders" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                Comenzi <Badge color="danger" pill>180 noi</Badge>
              </NavLink>
            </ListGroup>
        </div>
      );
    }

    return (
      <div>
        <h1>Administrare {global.appConfig.companyName}</h1>
        <Row>
          <Col className="mb-3" lg="3">
            { Menu }
          </Col>
          <Col lg="9">
            <Switch>
              <PrivateRoute exact path="/admin" component={pages.admin.home} />
              <PrivateRoute exact path="/admin/offers" component={pages.admin.offers} Requires="COMPANY_ADMIN" />
              <PrivateRoute exact path="/admin/tiers" component={pages.admin.tiers}  Requires="COMPANY_ADMIN" />
              <PrivateRoute exact path="/admin/orders" component={pages.admin.orders} />
              <PrivateRoute exact path="/admin/offer-creator" component={pages.admin.offerCreator} />
              <PrivateRoute exact path="/admin/company-info" component={pages.admin.companyInfo} Requires="COMPANY_ADMIN" />
              <PrivateRoute exact path="/admin/employees" component={pages.admin.employees} Requires="COMPANY_ADMIN" />
              <PrivateRoute exact path="/admin/partners" component={pages.admin.partners} Requires="COMPANY_ADMIN" />
              <PrivateRoute exact path="/admin/users" component={pages.admin.users} Requires="COMPANY_ADMIN" />
              <PrivateRoute exact path="/admin/reports" component={pages.admin.reports} Requires="COMPANY_ADMIN" />
              <PrivateRoute exact path="/admin/sms-settings" component={pages.admin.smsSettings} Requires="COMPANY_ADMIN" />
            </Switch>
          </Col>
        </Row>
      </div>
    );
  }
}
IndexPage.contextType = appContext;

export default IndexPage;
