import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import appContext from '../context/appContext';
import * as perm from '../helpers/permissionsHelper';

class PrivateRoute extends Component {
    render() {
        const {props} = this;

        if(this.context.isAuthed !== true) {
            return <Redirect to={{
                pathname: '/login',
                state: {
                    referrer: props.location.pathname,
                },
            }} />
        }

        if(props.RequiresCompany && this.context.userData.company_id === null) {
            return <Redirect to='/user/newcompany' />;
        }

        if(props.RequiresEither && !perm.hasEitherPerm(this.context.userPermissions, props.RequiresEither)) {
            return <Redirect to="/" />;
        }

        if(props.Requires && !perm.hasPerm(this.context.userPermissions, props.Requires)) {
            return <Redirect to="/" />;
        }

        return (
            <Route {...props} />
        );
    }
}
PrivateRoute.contextType = appContext;

export default PrivateRoute;