import React, { Component } from "react";
import AddressPicker from "../../components/addressPicker";

import {Form, Field} from 'simple-react-form'
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';

import {
  Input,
  Alert,
} from "reactstrap";


export default class EmailChangePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formState: {},

      confirmationPending: null,

      isLoading: true,
      success: undefined,
      error: undefined,
      errorMessage: undefined,
    };

    this.validator = new SimpleReactValidator({
      element: (message) => <div className='invalid-feedback' style={{display: "initial"}}>{message}</div>,
      validators: {
        matches: {  // name the rule
          message: 'The :attribute match the :field_to_match.',
          rule: (val, params) => {
            return val === this.state.formState[params[0]];
          },
          messageReplace: (message, params) => {
            if(!params[1]) {
              return message;
            }

            return message.replace(':field_to_match', params[1]);
          },
          required: true  // optional
        }
      },
    });

    this.submitCode = (event) => {
      event.preventDefault();
      
      if (this.validator.allValid()) {
        this.setState({isLoading: true});
      
        let Data = {
          code: this.state.formState.code,
        };
        
        axios.post('user/solve_email_change_request', Data)
          .then(() => {
            this.setState({
              isLoading: false,
              success: true,
              error: undefined,
            });
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
              error: true,
            });
            console.log(error)
          });
      } else {
        this.validator.showMessages();
        // rerender to show messages for the first time
        this.forceUpdate();
      }
    };
    this.submitRequest = (event) => {
      event.preventDefault();
      
      if (this.validator.allValid()) {
        this.setState({isLoading: true});

        let {formState} = this.state;
        
        let Data = {
          password: formState.password,
          new_email: formState.new_email,
        };
        
        axios.post('user/request_email_change', Data)
          .then(() => {
            this.getInfo()
              .then(() => {
                this.setState({
                  isLoading: false,
                  error: false,
                });
              }) 
              .catch(error => {
                this.setState({
                  isLoading: false,
                  error: true,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
              error: true,
            });
            if (error.response) {
              let {data, status} = error.response;
              if (status === 409 && data.status === 'email_already_in_use') {
                this.setState({
                  errorMessage: "Această adresă de e-mail este deja în uz!"
                })
              }
            }
          });
      } else {
        this.validator.showMessages();
        // rerender to show messages for the first time
        this.forceUpdate();
      }
    };
  }

  textInput(props) {
    return (
        <Input defaultValue={props.value}  type={props.contentType} onChange={event => props.onChange(event.target.value)} placeholder={props.placeholder} invalid={props.invalid}/>
    );
  }

  async getInfo() {
    let sellerInfo = await axios.get('user/email_change_request_pending');
    let {data} = sellerInfo;

    this.setState({confirmationPending: data.is_pending});
  }

  componentDidMount() {
    this.getInfo()
      .then(() => {
        this.setState({isLoading: false});
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    if(this.state.isLoading) {
      return (
        <h1>Încărcare...</h1>
      );
    }

    let content;
    
    if(this.state.success) {
      content = null;
    } else if(this.state.confirmationPending) {
      content = (
        <Form state={this.state.formState} onChange={state => this.setState({formState: state})}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Introdu codul de verificare</label>
              <Field contentType="text" fieldName="code" type={this.textInput} placeholder="Cod de verificare" />
              {this.validator.message('code', this.state.formState.code, 'required|string')}
            </div>
          </div>
          <button type="submit" className="btn btn-success" onClick={this.submitCode}>Finalizare</button>
        </Form>
      );
    } else {
      content = (
        <Form state={this.state.formState} onChange={state => this.setState({formState: state})}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Parola contului</label>
              <Field contentType="password" fieldName="password" type={this.textInput} placeholder="Parola" />
              {this.validator.message('password', this.state.formState.password, 'required|string|min:8')}
            </div>
            <div className="form-group col-md-6">
              <label>Adresa de e-mail</label>
              <Field contentType="email" fieldName="new_email" type={this.textInput} placeholder="Adresa nouă de e-mail" />
              {this.validator.message('new_email', this.state.formState.new_email, 'required')}
            </div>
          </div>
          <p>Un cod de verificare îți va fi trimis prin SMS.</p>
          <button type="submit" className="btn btn-success" onClick={this.submitRequest}>Schimbă adresa de e-mail</button>
        </Form>
      );
    }

    return (
      <div>
        <h1>Schimbare adresă de e-mail</h1>
        <p>Schimbă adresa de e-mail asociată acestui cont</p>

        { 
          this.state.success && (
            <Alert color="success">Email-ul a fost actualizat cu succes!</Alert>
          )
        }

        {this.state.error && (
          <Alert color="danger">O eroare a fost întâmpinată! <br />
            {this.state.errorMessage ? <span>{this.state.errorMessage}</span> : <span>Verificați datele introduse și reîncercați.</span>}
          </Alert>
        )}

        {content}
      </div>
    );
  }
}
