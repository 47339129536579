import React, { Component } from "react";
import axios from "axios";

import update from 'immutability-helper';

import {
    Button, ButtonGroup,
    Col, Row,
    Input, InputGroup
} from "reactstrap";

import Order from './order';

class OrderManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            filters: {
                orderID: "",
                status: null,
            },
            filtersApplied: true,
            page: 1,

            orders: [],
        };

        this.isFilterSet = (name, value) => {
            return (this.state.filters[name] === value);
        }

        this.setFilter = (name, value) => {
            if (this.state.filters[name] === value)
                return;

            let changes = {};
            changes[name] = { $set: value };

            this.setState({
                filters: update(this.state.filters, changes),
                filtersApplied: false
            });
        }

        this.applyFilters = () => {
            this.setState({
                isLoaded: false,
            }, () => {
                this.retrieveOrders()
                    .then(() => {
                        this.setState({
                            isLoaded: true,
                            filtersApplied: true,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            });
        }

        this.setPage = (pageNumber) => {
            if (pageNumber < 0) {
                return;
            }

            this.setState({
                page: pageNumber,
                isLoaded: false,
            }, () => {
                this.retrieveOrders()
                    .then(() => {
                        this.setState({
                            isLoaded: true,
                            filtersApplied: true,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            });
        }

        this.refresh = this.refresh.bind(this);
    }

    async retrieveOrders() {
        let orderInfoReply = await axios.get('order/get_orders', {
            params: {
                seller_id: global.appConfig.companyID,
                page: this.state.page,
                page_count: this.props.pageSize,
                status: this.state.filters.status,
                order_id: this.state.filters.orderID,
                company_id: this.props.companyID,
            }
        });

        let orderInfo = orderInfoReply.data;
        this.setState({
            orders: orderInfo
        });
    }

    refresh() {
        this.setState({isLoaded: false}, () => {
            this.retrieveOrders()
                .then(() => {
                    this.setState({ isLoaded: true });
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }

    componentDidMount() {
        this.refresh();
    }

    renderFilters() {
        return (
            <div>
                <h2>Filtre</h2>
                <h4 className="mt-2">Număr comandă</h4>
                <InputGroup>
                    <Input placeholder="#" type="number" defaultValue={this.state.filters.orderID} onChange={(event) => this.setFilter('orderID', event.target.value)} />
                </InputGroup>

                <h4 className="mt-2">Stare comandă</h4>
                <ButtonGroup className="d-flex" vertical={this.props.layout === "wide"}>
                    <Button className="flex-fill" size="sm" color="primary" onClick={() => this.setFilter('status', null)} active={this.isFilterSet('status', null)}>Toate</Button>
                    <Button className="flex-fill" size="sm" color="primary" onClick={() => this.setFilter('status', "PLACED")} active={this.isFilterSet('status', "PLACED")}>Neprocesate</Button>
                    <Button className="flex-fill" size="sm" color="primary" onClick={() => this.setFilter('status', "PROCESSING")} active={this.isFilterSet('status', "PROCESSING")}>În lucru</Button>
                    <Button className="flex-fill" size="sm" color="primary" onClick={() => this.setFilter('status', "EXPEDITED")} active={this.isFilterSet('status', "EXPEDITED")}>Livrate</Button>
                    <Button className="flex-fill" size="sm" color="primary" onClick={() => this.setFilter('status', "CANCELLED")} active={this.isFilterSet('status', "CANCELLED")}>Anulate</Button>
                </ButtonGroup>

                {(!this.state.filtersApplied) && <Button color="success" block onClick={this.applyFilters} className="mt-2">Aplică filtre</Button>}
            </div>
        );
    }

    renderOrders() {
        let { pageSize } = this.props;

        return (
            <div>
                <div className="d-flex justify-content-between">
                    <h2>Listă de comenzi</h2>
                    <Button color="info" onClick={this.refresh}>Reîncarcă</Button>
                </div>
                {
                    (this.state.orders.length > 0) ? (
                        <div>
                            <p>Se afișează rezultatele {(this.state.page - 1) * pageSize + 1} - {(this.state.page) * pageSize}.</p>
                            {
                                this.state.orders.map((order, id) => (
                                    <Order key={id} {...order} editable={this.props.editable} onUpdate={() => this.refresh(id)} />
                                ))
                            }
                            <div className="d-flex justify-content-between">
                                <Button color="primary" onClick={() => this.setPage(this.state.page - 1)} disabled={this.state.page === 1}>Pagina precedentă</Button>
                                <Button color="primary" onClick={() => this.setPage(this.state.page + 1)} disabled={this.state.orders.length < pageSize}>Pagina următoare</Button>
                            </div>
                        </div>
                    ) : (
                            <p>Niciun rezultat</p>
                        )
                }
            </div>
        );
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <h1>Încărcare...</h1>
            );
        }

        if(this.props.layout === "wide") {
            return (
                <Row>
                    <Col sm="3">
                        {this.renderFilters()}
                    </Col>
                    <Col sm="9">
                        {this.renderOrders()}
                    </Col>
                </Row>
            );
        }

        return (
            <div>
                <Row>
                    <Col sm="6">
                        {this.renderFilters()}
                    </Col>
                </Row>
                <hr />
                {this.renderOrders()}
            </div>
        );
    }
}

export default OrderManager;