import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Row,
  Col,
} from "reactstrap";
import { ListGroup, ListGroupItem } from 'reactstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import AddressViewer from "../../components/addressViewer";

class InfoPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sellerInfo: {
        brandName: null,
        email: null,
        website: null,
        phone: null,
        fax: null,
        addressID: null,
      },

      isLoaded: false,
    };
  }

  async getInfo() {
    let sellerInfo = await axios.get('seller/contact_info');
    let { data } = sellerInfo;

    let sellerData = {
      brandName: data.brand_name,
      email: data.email,
      website: data.website,
      phone: data.phone,
      fax: data.fax,
      addressID: data.address_id,
    };

    this.setState({ sellerInfo: sellerData });
  }

  componentDidMount() {
    this.getInfo()
      .then(() => {
        this.setState({ isLoaded: true });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <h1>Încărcare...</h1>
      );
    }

    let {sellerInfo} = this.state;
    return (
      <div>
        <h1 className="align-middle">
          <Link to="../">
            <Button color="primary">
              <FontAwesomeIcon icon="long-arrow-alt-left" />
            </Button>
          </Link>
          &nbsp;Informații de contact
        </h1>
        <div>
          <h2>{sellerInfo.brandName}</h2>
          <Row>
            <Col md="6">
              <ListGroup>
                <ListGroupItem><strong>Email:</strong> {sellerInfo.email}</ListGroupItem>
                <ListGroupItem><strong>Website:</strong> <a href={sellerInfo.website}>{sellerInfo.website}</a></ListGroupItem>
                <ListGroupItem><strong>Telefon:</strong> {sellerInfo.phone}</ListGroupItem>
                <ListGroupItem><strong>Fax:</strong> {sellerInfo.fax}</ListGroupItem>
              </ListGroup>
            </Col>
            <Col md="6">
              <h3>Adresă</h3>
              <AddressViewer addressID={sellerInfo.addressID} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default InfoPage;
