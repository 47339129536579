import React, { Component } from "react";

import axios from "axios";

import {
    Alert,
    Badge,
} from "reactstrap";

export default class AddressViewer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addressData: null,
            loaded: false,
            error: null,
        };
    }

    async getAddress() {
        let response = await axios.get("address/get_address", {
            params: {
                address_id: this.props.addressID,
            }
        });

        this.setState({
            addressData: response.data,
        });
    }

    componentDidMount() {
        this.getAddress()
            .then(() => {
                this.setState({ loaded: true });
            })
            .catch((error) => {
                this.setState({ error: "Eroare la interogarea sistemului!" });
            });
    }

    render() {
        if (!this.state.loaded) {
            return (
                <Alert color="info" fade={false}>
                    Încărcare
                </Alert>
            );
        }

        if (this.state.error) {
            return (
                <div>
                    <Badge color="danger" className="mr-2">(Nu s-a putut obține adresa)</Badge>
                </div>
            );
        }

        let address = this.state.addressData;

        return (
            <div>
                <p>
                    <span>{address.street} {address.street_no},</span><br />
                    {address.other && <span>{address.other},<br /></span>}
                    <span>{address.city}, {address.county},</span><br />
                    <span>C.P. {address.postal_code}</span><br />
                </p>
            </div>
        )
    }
}