import React, { Component } from "react";

import {Table} from "reactstrap";

class OrderSummary extends Component {
    render() {
        let listIndex = 0;
        let grandTotal = 0;
        
        let {products, size} = this.props;

        if(!products) {
            return (
                <Table striped responsive size={size} style={{tableLayout: "fixed", wordWrap: "break-word"}}>
                <thead>
                    <tr>
                        <th style={{width: "5%"}}>#</th>
                        <th style={{width: "12%"}}>Cod</th>
                        <th style={{width: "40%"}}>Denumire</th>
                        <th>Cantitate</th>
                        <th>Preț unitar</th>
                        <th>Preț</th>
                    </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="6"><span className="float-left">Niciun produs</span></td>
                  </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <th>Total</th>
                        <th><span className="float-right">{grandTotal.toFixed(2)} RON</span></th>
                    </tr>
                    <tr>
                        <td colSpan="6"><span className="float-right" style={{fontSize: "0.7rem"}}>Prețurile afișate nu conțin TVA</span></td>
                    </tr>
                </tfoot>
            </Table>
            )
        }

        return (
            <Table striped responsive size={size} style={{tableLayout: "fixed", wordWrap: "break-word"}}>
                <thead>
                    <tr>
                        <th style={{width: "5%"}}>#</th>
                        <th style={{width: "12%"}}>Cod</th>
                        <th style={{width: "40%"}}>Denumire</th>
                        <th>Cantitate</th>
                        <th>Preț unitar</th>
                        <th>Preț</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((item) => {
                        listIndex++;
                        grandTotal += (item.quantity * item.price);

                        return (
                            <tr key={listIndex}>
                                <th scope="row">{listIndex}</th>
                                <td>{item.code}</td>
                                <td>{item.name}</td>
                                <td>{item.quantity}</td>
                                <td>
                                    <span className="float-right">
                                        {Number(item.price).toFixed(2)} {item.currency}
                                    </span>
                                </td>
                                <td>
                                    <span className="float-right">
                                        {(item.quantity * item.price).toFixed(2)} {item.currency}
                                    </span>
                                </td>
                            </tr>
                        );
                    })}
                    {products.map((item) => {
                        return item.discounts.map((discount) => {
                            listIndex++;

                            let discountText = null;
                            let discountValue = null;

                            if (discount.type === "percentage") {
                                discountText = `${parseFloat(discount.value).toFixed(0)}% reducere`;
                                discountValue = (discount.value / 100) * item.price * item.quantity;
                            }

                            let discountReason = null;
                            if (discount.why.reason === "discount_group") {
                                discountReason = `pentru statutul de client ${discount.why.tier_name}`;
                            } else if(discount.why.reason === "offer") {
                                discountReason = `conform ofertei ${discount.why.offer_name}`;
                            }

                            grandTotal += discountValue;

                            return (
                                <tr key={listIndex}>
                                    <th scope="row"></th>
                                    <td></td>
                                    <td>
                                        <span style={{fontSize: ".8rem"}}>{discountText} la <i>{item.name}</i> (#{item.code}) {discountReason}</span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="float-right">{discountValue.toFixed(2)} {item.currency}</span></td>
                                </tr>
                            )
                        });
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <th>Total</th>
                        <th><span className="float-right">{grandTotal.toFixed(2)} RON</span></th>
                    </tr>
                    <tr>
                        <td colSpan="6"><span className="float-right" style={{fontSize: "0.7rem"}}>Prețurile afișate nu conțin TVA</span></td>
                    </tr>
                </tfoot>
            </Table>
        );
    }
}

export default OrderSummary;
