import React, { Component } from "react";

import axios from "axios";

import AddressCreator from "./addressCreator";

import {
    Alert,
    Badge,
    Button,
    ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
    Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class AddressPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addresses: [],
      selected: null,
      loaded: false,
      pickerOpen: false,
      newOpen: false,
    }

    this.togglePicker = () => {
        this.setState({pickerOpen: !this.state.pickerOpen});
    };

    this.toggleNew = () => {
        this.setState({newOpen: !this.state.newOpen});
    }

    this.select = (index) => {
        if(index >= this.state.addresses.length || index < 0)
            return;
        
        this.setState({selected: index});

        if(this.props.onSelect) {
            this.props.onSelect(this.state.addresses[index].id);
        }
    }

    this.onSaveAddress = (addressID) => {
        this.setState({loaded: false}, () => {
            this.getAddresses()
                .then(() => {
                    this.setState({loaded: true});
                })
                .catch((error) => {
                    this.setState({error: "Eroare la interogarea sistemului!"});
                });
        });
    }
  }

  async getAddresses() {
    let addressesResponse = await axios.get("address/get_addresses");

    this.setState({
        addresses: addressesResponse.data,
        selected: null,
    });

    if(addressesResponse.data) {
        let selectedIndex = 0;
        
        if(this.props.defaultAddressID) {
            addressesResponse.data.forEach((address, index) => {
                if(address.id === this.props.defaultAddressID) {
                    selectedIndex = index;
                }
            });
        }

        this.select(selectedIndex);
    } 
  }

  componentDidMount() {
    this.getAddresses()
      .then(() => {
        this.setState({loaded: true});
      })
      .catch((error) => {
        this.setState({error: "Eroare la interogarea sistemului!"});
      });
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Alert color="info" fade={false}>
            Încărcare
        </Alert>
      );
    }

    if(this.state.addresses.length === 0) {
        return (
            <div>
                <Badge color="danger" className="mr-2">(Nicio adresă în baza de date)</Badge>
                <Button color="info" onClick={this.toggleNew}><FontAwesomeIcon icon="plus" /> Adaugă o adresă nouă</Button>
                <AddressCreator isOpen={this.state.newOpen} toggle={this.toggleNew} onSave={this.onSaveAddress} />
            </div>
        );
    }

    const selectedAddress = this.state.addresses[this.state.selected];

    return (
        <div>
            <p>
                <span>{selectedAddress.street} {selectedAddress.street_no},</span><br />
                {selectedAddress.other && <span>{selectedAddress.other},<br /></span>}
                <span>{selectedAddress.city}, {selectedAddress.county},</span><br />
                <span>C.P. {selectedAddress.postal_code}</span><br />
            </p>
            { (this.state.addresses.length > 1) && (
                <Button color="primary mr-2" onClick={this.togglePicker}><FontAwesomeIcon icon="edit" /> Schimbă adresa</Button>
            )}
            <Button color="info" onClick={this.toggleNew}><FontAwesomeIcon icon="plus" /> Adaugă o adresă nouă</Button>

            <Modal fade={false} isOpen={this.state.pickerOpen} toggle={this.togglePicker}>
                <ModalHeader toggle={this.togglePicker}>Alege o altă adresă</ModalHeader>
                <ModalBody>
                    <ListGroup>
                        {
                            this.state.addresses.map((address, index) => (
                                <ListGroupItem key={index} active={(this.state.selected === index)} tag="a" href="#" action
                                    onClick={() => {
                                        this.select(index);
                                        this.togglePicker();
                                    }}
                                >
                                    <ListGroupItemHeading>{address.street} {address.street_no}</ListGroupItemHeading>
                                    <ListGroupItemText>
                                        {address.other && <span>{address.other},<br /></span>}
                                        <span>{address.city}, {address.county},</span><br />
                                        <span>C.P. {address.postal_code}</span><br />
                                    </ListGroupItemText>
                                </ListGroupItem>
                            ))
                        }
                    </ListGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.togglePicker}>Închide</Button>
                </ModalFooter>
            </Modal>

            <AddressCreator isOpen={this.state.newOpen} toggle={this.toggleNew} onSave={this.onSaveAddress} />
        </div>
    )
  }
}