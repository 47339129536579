import React, { Component } from "react";

import { 
  ListGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import {Form, Field} from 'simple-react-form'
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import DiscountGroupInfo from "../../components/discountGroupInfo";

export default class TiersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupIDs: [],
      isLoaded: false,
      modalOpen: false,
      formState: {},
    };

    this.toggleModal = () => {
      this.setState({modalOpen: !this.state.modalOpen});
    };

    this.validator = new SimpleReactValidator({
      element: (message) => <div className='invalid-feedback' style={{display: "initial"}}>{message}</div>
    });

    this.onSubmit = (event) => {
      event.preventDefault();
      
      if (this.validator.allValid()) {
        this.setState({isLoaded: false});
        this.createGroup()
          .then(() => {
            this.setState({
              isLoaded: true,
              formState: {},
              modalOpen: false,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.validator.showMessages();
        // rerender to show messages for the first time
        this.forceUpdate();
      }
    };

    this.refresh = () => {
      this.setState({isLoaded: false});
      this.forceUpdate(() => {
        this.setState({isLoaded: true});
      });
    }
  }

  async getGroupIDs() {
    let response = await axios.get('discount_group/get_groups');

    this.setState({
      groupIDs: response.data.ids,
    });
  }

  async createGroup() {
    let response = await axios.get('discount_group/create', {
      params: {
        name: this.state.formState.name,
      }
    });

    let groupIDs = this.state.groupIDs;
    groupIDs.unshift(response.data.id);
    this.setState({groupIDs: groupIDs});
  }

  componentDidMount() {
    this.setState({isLoaded: false}, () => {
      this.getGroupIDs()
        .then(() => {
          this.setState({isLoaded: true});
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  textInput(props) {
    return (
        <Input defaultValue={props.value}  type={props.contentType} onChange={event => props.onChange(event.target.value)} placeholder={props.placeholder} invalid={props.invalid}/>
    );
  }

  render() {
    if(!this.state.isLoaded) {
      return (
        <div>
          <h2>Încărcare...</h2>
        </div>
      );
     }

    return (
      <div>
        <h2>Ajustări de preț</h2>
        <Button color="success" onClick={this.toggleModal}>Adaugă program nou</Button>
        <Modal fade={false} isOpen={this.state.modalOpen} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Adaugă program nou</ModalHeader>
          <ModalBody>
            <Form state={this.state.formState} onChange={state => this.setState({formState: state})}>
              <FormGroup row>
                <Label sm={2}>Nume</Label>
                <Col sm={10}>
                  <Field contentType="text" fieldName="name" type={this.textInput} placeholder="Denumire program" />
                  {this.validator.message('name', this.state.formState.name, 'required')}
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModal}>Închide</Button>
            <Button color="success" onClick={this.onSubmit}>Salvează</Button>
          </ModalFooter>
        </Modal>
        <hr />
        <ListGroup>
          {
            this.state.groupIDs.map((id, index) => {
              return <DiscountGroupInfo key={index} discountGroupID={id} onRefreshTiers={this.refresh} />;
            })
          }
        </ListGroup>
      </div>
    );
  }
}