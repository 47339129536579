import React, { Component } from "react";

import {
  Badge,
  Card,
  CardTitle,
  CardText,
  CardImg,
  CardBody,
  CardColumns,
  CardFooter,
  CardHeader,
  Button
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OfferCreator from "../../components/offerCreator";
import Axios from "axios";
import Offer from "../../components/offer";

class OffersPage extends Component {
  state = {
    open: false,
    offerData: [],
  };

  toggleOfferCreator = () => {
    this.setState({ open: !this.state.open });
  };

  async getOffers() {
    let offerResult = await Axios.get('offer/get_offers');
    return offerResult.data.offers;
  }

  async deleteOffer(offerID) {
    let deleteResult = await Axios.get('offer/delete', {
      params: {
        offer_id: offerID
      }
    });
  }

  refresh() {
    this.getOffers()
      .then(offerData => this.setState({offerData: offerData}))
      .catch(error => console.log(error));
  }

  delete(offerID) {
    this.deleteOffer(offerID)
      .then(() => this.refresh())
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this.refresh();
  }

  render() {
    return (
      <div>
        <h2>Administrează ofertele</h2>
        <h3>Ofertă nouă</h3>
        <Button onClick={this.toggleOfferCreator}>Ofertă nouă</Button>
        <OfferCreator
          toggle={this.toggleOfferCreator}
          isOpen={this.state.open}
          onUpdate={() => this.refresh()}
        />
        <hr />
        <h3>Oferte curente și viitoare</h3>
        {this.state.offerData.length === 0 && (
          <p>Nu există nicio ofertă curentă sau viitoare</p>
        )}
        {
          this.state.offerData.map((offer, index) => (
            <Offer key={index} canDelete={true} onDelete={(ID) => this.delete(ID)} {...offer} />
          ))
        }
      </div>
    );
  }
}

export default OffersPage;
