export default {
    index: require('./index').default,
    home: require('./home').default,
    offers: require('./offers').default,
    tiers: require('./tiers').default,
    orders: require('./orders').default,
    companyInfo: require('./company-info').default,
    offerCreator: require('./offer-creator').default,
    employees: require('./employees').default,
    users: require('./users').default,
    partners: require('./partners').default,
    reports: require('./reports').default,
    smsSettings: require('./sms-settings').default,
};