import React, { Component } from "react";

export default class HomePage extends Component {
  render() {
    return (
      <div>
        <h1>Acasă</h1>
        <p>Bine ai venit la pagina de administrare!</p>
        <p>Alege, te rog, una din opțiunile din stânga.</p>
        {/*<CardColumns style={{columnCount: 2}}>
          <Card className="text-center">
            <CardBody>
              <CardText
                className="text-success"
                style={{ fontSize: "3em" }}
                  >
                    865 EUR
              </CardText>
              <CardTitle tag="h5">Vânzări săptămâna asta</CardTitle>
            </CardBody>
          </Card>
          <Card className="text-center">
            <CardBody>
              <CardText
                className="text-danger"
                style={{ fontSize: "3em" }}
                  >
                    16
              </CardText>
              <CardTitle tag="h5">Parteneri activi</CardTitle>
            </CardBody>
          </Card>
          <Card className="text-center">
            <CardBody>
              <CardText
                className="text-primary"
                style={{ fontSize: "3em" }}
                  >
                  110
              </CardText>
              <CardTitle tag="h5">Comenzi onorate luna asta</CardTitle>
            </CardBody>
          </Card>
    </CardColumns>*/}
      </div>
    );
  }
}
