import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import axios from "axios";

import {Alert, Button, Col, Form, FormGroup, Input, Label} from "reactstrap";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      emailValid: true,
      passwordValid: true,
      loginErrorMessage: null,

      referrer: '/',
    };
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    // Validate the form inputs
    let isEmailInvalid = !this.state.email;
    let isPasswordInvalid = !this.state.password;
    this.setState({
      emailValid: !isEmailInvalid,
      passwordValid: !isPasswordInvalid
    });

    // Call the main login handler
    if (!isEmailInvalid && !isPasswordInvalid) {
      let LoginData = new FormData();
      LoginData.append("email", this.state.email);
      LoginData.append("password", this.state.password);
  
      axios({
        method: "post",
        url: "user/login",
        data: LoginData,
        config: { headers: { "Content-Type": "multipart/form-data" }}
      })
      .then(response => {
        const { state = {} } = this.props.location;
        const { referrer } = state;

        this.setState({referrer: (referrer || "/")});
        this.props.userDataCallback(response.data.user_data);
      })
      .catch(error => {
        let response = error.response.data;
        let errorMessage = null;

        if(response.status) {
          let messages = {
            "invalid_input": "Formatul datelor de intrare este incorect",
            "incorrect_login_data": "Nume sau parolă greșită"
          }

          switch(response.status) {
            case "company_ban":
              errorMessage = <span>Accesul companiei dvs. la platformă este blocat. Motiv: <i>{response.reason}</i></span>;
              break;

            default:
              if(messages[response.status]) {
                errorMessage = messages[response.status];
              }
          }
        }

        this.setState({
          loginError: true,
          loginErrorMessage: errorMessage,
        });
      });
    }
  }

  render() {
    if (this.props.authenticated) {
      return <Redirect to={this.state.referrer} />;
    }

    let loginError = null;
    if(this.state.loginError === true) {
      loginError = (
        <Alert color="danger">
          <span>O eroare a fost întâmpinată la autentificare!</span>
          <hr />
          {this.state.loginErrorMessage && <span>
            {this.state.loginErrorMessage}
          </span>}
        </Alert>
      );
    }

    return (
        <div>
          <Alert color="warning">
            <p>Stimați parteneri,</p>

            <p>Trecerea la noul importator de piese Opel a fost realizată.</p>

            <p>
              Vă anunțăm faptul că prețurile pieselor din platformă nu sunt actualizate - acestea vor fi reofertate
              pe mail în momentul în care faceți comanda. <br />

              Termenele de livrare pentru piesele care se comandă la extern suferă întârzieri pentru moment. <br />

              În momentul în care vom avea informații noi, vă vom anunța.
            </p>

            <p>Vă mulțumim!</p>
          </Alert>

          <div className="mx-auto" style={{ width: "30%", minWidth: "200px" }}>
            <h1>Conectare</h1>

            {loginError}
            <Form onSubmit={this.handleSubmit}>
              <FormGroup row>
                <Label sm={2}>Email</Label>
                <Col sm={10}>
                  <Input
                      type="text"
                      name="email"
                      placeholder="Adresă de email"
                      onChange={this.handleEmailChange}
                      invalid={!this.state.emailValid}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2}>Parolă</Label>
                <Col sm={10}>
                  <Input
                      type="password"
                      name="password"
                      placeholder="Parolă"
                      onChange={this.handlePasswordChange}
                      invalid={!this.state.passwordValid}
                  />
                </Col>
              </FormGroup>
              <Button color="success" block>
                <FontAwesomeIcon icon="sign-in-alt" className="mr-2" />
                Conectare
              </Button>
            </Form>
          </div>

        </div>
    );
  }
}

export default LoginPage;
