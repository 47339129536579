import React, { Component } from "react";

import {
  Button,
  Col,
  Input,
  FormGroup,
  Alert,
  Label
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Form, Field} from 'simple-react-form'
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';

class RegisterPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formState: {
        name: null,
        phone: null,
        email: null,
        password: null,
      },

      isLoading: false,
      success: undefined,
      error: undefined,
    };

    this.validator = new SimpleReactValidator({
      element: (message) => <div className='invalid-feedback' style={{display: "initial"}}>{message}</div>
    });

    this.OnSubmit = (event) => {
      event.preventDefault();
      
      if (this.validator.allValid()) {
        this.setState({isLoading: true});
        this.RegisterUser()
          .then(() => {
            this.setState({isLoading: false});
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
              success: undefined,
              error: true,
            });
            console.log(error)
          });
      } else {
        this.validator.showMessages();
        // rerender to show messages for the first time
        this.forceUpdate();
      }
    };
  }

  async RegisterUser() {
    let {formState} = this.state;

    let UserData = new FormData();
    UserData.append("name", formState.name);
    UserData.append("email", formState.email);
    UserData.append("phone", formState.phone);
    UserData.append("password", formState.password);

    let registerResponse = await axios({
      method: "post",
      url: "user/register",
      data: UserData,
      config: { headers: { "Content-Type": "multipart/form-data" }}
    });

    if(registerResponse.data.status === "success") {
      this.setState({
        success: true,
        error: undefined,
      });
    }
  }

  textInput(props) {
    return (
        <Input defaultValue={props.value}  type={props.contentType} onChange={event => props.onChange(event.target.value)} placeholder={props.placeholder} invalid={props.invalid}/>
    );
  }

  render() {
    let Content;
    if(this.state.isLoading) {
      Content = (
        <h1>Încărcare</h1>
      );
    } else if(this.state.success) {
      Content = (
        <p>Ați fost înregistrat cu succes!</p>
      );
    } else {
      Content = (
        <Form state={this.state.formState} onChange={state => this.setState({formState: state})}>
          <FormGroup row>
            <Label sm={2}>Nume</Label>
            <Col sm={10}>
              <Field contentType="text" fieldName="name" type={this.textInput} placeholder="Nume și prenume" />
              {this.validator.message('name', this.state.formState.name, 'required')}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>Telefon</Label>
            <Col sm={10}>
              <Field contentType="text" fieldName="phone" type={this.textInput} placeholder="Număr de telefon" />
              {this.validator.message('phone', this.state.formState.phone, 'required|phone')}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>Email</Label>
            <Col sm={10}>
              <Field contentType="email" fieldName="email" type={this.textInput} placeholder="Adresă de email" />
              {this.validator.message('email', this.state.formState.email, 'required|email')}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>Parolă</Label>
            <Col sm={10}>
              <Field contentType="password" fieldName="password" type={this.textInput} placeholder="Parolă" />
              {this.validator.message('password', this.state.formState.password, 'required')}
            </Col>
          </FormGroup>
          <Button color="success" block onClick={this.OnSubmit}>
            <FontAwesomeIcon icon="sign-in-alt" className="mr-2" />Înregistrare
          </Button>

        </Form>
      );
    }

    return (
      <div className="mx-auto" style={{ width: "30%" }}>
        <h1>Înregistrare</h1>
        {this.state.error && (
          <Alert color="danger">A fost întâmpinată o eroare!</Alert>
        )}
        {Content}
      </div>
    );
  }
}

export default RegisterPage;
