import React, { Component } from "react";
import axios from "axios";

import {
    Alert,
    Button,
    Table,
    Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";
import CompanySearcher from "./companySearcher";

export default class CompanyMultiPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companies: [],
            loaded: false,
            error: null,
            searcherOpen: false,
        }

        this.onAddCompany = (companyID) => {
            let isDuplicate = false;
            this.state.companies.forEach((company) => {
                if(company.id == companyID) {
                    isDuplicate = true;
                }
            })

            if(isDuplicate) {
                this.setState({
                    error: "Compania selectată este deja în listă!",
                });
                return;
            }

            this.getCompanyInfo(companyID)
                .then((companyData) => {
                    let {companies} = this.state;
                    companies.unshift(companyData);
                    this.setState({companies: companies});
                })
                .catch((error) => console.log(error));
        }

        this.onRemoveCompany = (companyID) => {
            let indexToDelete = null;
            let {companies} = this.state;

            companies.forEach((company, index) => {
                if(company.id == companyID) {
                    indexToDelete = index;
                }
            })

            if(indexToDelete === null)
                return;

            companies.splice(indexToDelete, 1);
            this.setState({companies: companies});
        }

        this.save = () => {
            if(!this.props.onSave) {
                return;
            }

            let companyIDs = [];

            this.state.companies.forEach((company) => {
                companyIDs.push(company.id);
            });

            this.props.onSave(companyIDs);
            
            if(this.props.toggle) {
                this.props.toggle();
            }
        }

        this.toggleSearcher = () => {
            this.setState({ searcherOpen: !this.state.searcherOpen });
        } 
    }

    async getCompanyInfo(companyID) {
        let companyResponse = await axios.get('company/info', {
            params: {
                company_id: companyID,
            }
        });

        return companyResponse.data;
    }

    async getMultipleCompaniesInfo(companyIDArray) {
        let companyData = [];

        if(!companyIDArray) {
            return [];
        }
        
        companyIDArray.forEach(async (id) => {
            let CompanyInfo = await this.getCompanyInfo(id);
            companyData.push(CompanyInfo);
        })

        return companyData;
    }

    componentDidMount() {
        this.getMultipleCompaniesInfo(this.props.defaultCompanies)
            .then((companyData) => {
                this.setState({
                    loaded: true,
                    companies: companyData,
                });
            })
            .catch((error) => console.log(error));
    }

    render() {
        return (
            <Modal fade={false} isOpen={this.props.isOpen} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>Selectează companii</ModalHeader>
                <ModalBody>
                    <Button color="success" block onClick={this.toggleSearcher}>Adaugă companie</Button>
                    <CompanySearcher isOpen={this.state.searcherOpen} toggle={this.toggleSearcher} onPick={this.onAddCompany} />

                    <h4 className="mt-2">Companii selectate</h4>
                    {
                        (this.state.error) && (
                            <Alert color="danger">{this.state.error}</Alert>
                        )
                    }
                    {
                        (this.state.companies.length > 0) ? (
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th style={{width: "80%"}}>Nume</th>
                                        <th>Elimină</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.companies.map((company) => {
                                            return (
                                                <tr key={company.id}>
                                                    <td style={{verticalAlign: "middle"}}>{company.name}</td>
                                                    <td><Button color="danger" block onClick={() => this.onRemoveCompany(company.id)}>X</Button></td>
                                                </tr>
                                            );
                                        })
                                    }
        
                                </tbody>
                            </Table>
                        ) : (
                            <p>Nicio companie selectată</p>
                        )
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.save}>Salvează</Button>
                    <Button color="secondary" onClick={this.props.toggle}>Închide</Button>
                </ModalFooter>
            </Modal>
        )
    }
}