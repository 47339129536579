import React, { Component } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

import { Button, Form, FormGroup, Label, Input } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Product from "../../components/product";

class SearchPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: "",
      searchTermValid: true,
      searchResults: [],
      didSearch: false,
      isLoading: false,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
  }

  handleSearchTermChange(event) {
    this.setState({ searchTerm: event.target.value });

    if (!event.target.value) {
      this.setState({ searchTermValid: false });
    }
  }

  handleSearch(event) {
    event.preventDefault();

    if (!this.state.searchTerm) {
      this.setState({ searchTermValid: false });
      return;
    }

    this.setState({
      searchTermValid: true,
      isLoading: true,
    });

    axios
      .get("product/search", {
        params: {
          search_string: this.state.searchTerm
        }
      })
      .then(response => {
        let Data = response.data;
        let results = [];

        Data.results.forEach(item => {
          let info = item.info;

          results.push({
            id: info.id,
            code: info.code,
            oldCode: info.old_code,
            name: info.name,
            manufacturerName: info.manufacturer_name,
            price: info.price,
            currency: info.currency,
            stock: info.stock_cache,
            discounts: item.discounts,
            isSpecial: item.is_special,
          });
        });

        this.setState({
          searchResults: results,
          didSearch: true,
          isLoading: false,
        });
      })
      .catch(error => {
        // handle error
        console.log(error);
      });
  }

  render() {
    let searchResults;

    if (this.state.didSearch) {
      searchResults = (
        <div>
          <h2>Rezultatele căutării</h2>
          {this.state.searchResults.length === 0 && (
            <p>
              Nu a fost găsit niciun produs după termenul de căutare introdus
            </p>
          )}

          {this.state.searchResults.map((item, i) => (
            <Product
              key={i}
              {...item}
              updateBasketCountCallback={this.props.updateBasketCountCallback}
            />
          ))}
        </div>
      );
    }

    return (
      <div>
        <h1 className="align-middle">
          <Link to="../">
            <Button color="primary">
              <FontAwesomeIcon icon="long-arrow-alt-left" />
            </Button>
          </Link>
          &nbsp;Caută o piesă
        </h1>
        <Form onSubmit={this.handleSearch}>
          <FormGroup>
            <Label for="partName" />
            <Input
              type="text"
              name="partName"
              placeholder="Introdu ID-ul piesei"
              onChange={this.handleSearchTermChange}
              invalid={!this.state.searchTermValid}
            />
          </FormGroup>

          <Button color="success">Caută</Button>
        </Form>

        <hr />

        {this.state.isLoading && <p>Căutare...</p>}

        {!this.state.isLoading && searchResults}
      </div>
    );
  }
}

export default SearchPage;
