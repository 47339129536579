import React, { Component } from "react";
import axios from "axios";

import {
    Input,
    Button,
    ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
    Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";

export default class CompanySearcher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companies: [],
            searchTerm: null,
            loaded: false,
            searched: false,
        }

        this.onSearchTermChange = (event) => {
            this.setState({ searchTerm: event.target.value }, this.refresh);
        }
    }

    async getCompanies() {
        if(!this.state.searchTerm) {
            this.setState({
                searched: true,
                companies: []
            });
            return;
        }

        let searchResponse = await axios.get('company/search', {
            params: {
                string: this.state.searchTerm,
            }
        });

        this.setState({
            searched: true,
            companies: searchResponse.data.results
        });
    }

    refresh() {
        this.getCompanies()
            .catch((error) => {
                console.log(error);
            })
    }

    render() {
        return (
            <Modal fade={false} isOpen={this.props.isOpen} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>Alege o companie</ModalHeader>
                <ModalBody>
                    <Input type="text" placeholder="Introdu numele companiei sau CUI" onChange={this.onSearchTermChange} />
                    <ListGroup>
                        {
                            (this.state.searched) && (
                                <h4 className="mt-2">Rezultatele căutării</h4>
                            )
                        }
                        {
                            (this.state.companies.length === 0 && this.state.searched) && (
                                <p>Niciun rezultat</p>
                            )
                        }
                        {
                            this.state.companies.map((company, index) => (
                                <ListGroupItem key={index} tag="a" href="#" action
                                    onClick={() => {
                                        if(this.props.onPick) {
                                            this.props.onPick(company.id);
                                        }
                                        if(this.props.toggle) {
                                            this.props.toggle();
                                        }
                                    }
                                }>
                                    <ListGroupItemHeading>{company.name}</ListGroupItemHeading>
                                    <ListGroupItemText>
                                        <strong>CUI</strong> <span>{company.tax_id}</span><br />
                                        <strong>CIF</strong> <span>{company.trade_register_id}</span><br />
                                    </ListGroupItemText>
                                </ListGroupItem>
                            ))
                        }
                    </ListGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.props.toggle}>Închide</Button>
                </ModalFooter>
            </Modal>
        )
    }
}