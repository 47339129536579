import React, { Component } from "react";

import {
  Badge,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardFooter,
  Button,
  Row,
  Col,
  Input,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

import {Link} from 'react-router-dom';
import axios from 'axios';
import OrderSummary from './orderSummary';
import OrderStatusEditor from "./orderStatusEditor";

class Order extends Component {
  constructor(props) {
    super(props);

    this.state = {
      summaryOpen: false,
      summaryLoaded: false,

      statusEditorOpen: false,

      productData: [],

      newStatus: props.status,
    }

    this.toggleSummary = () => {
      this.setState({ summaryOpen: !this.state.summaryOpen });

      if (!this.state.summaryLoaded) {
        this.getOrderProducts()
          .then(() => {
            this.setState({ summaryLoaded: true });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    this.handleOnUpdate = () => {
      if(this.props.onUpdate) {
        this.props.onUpdate();
      }
    }

    this.toggleStatusEditor = () => this.setState({statusEditorOpen: !this.state.statusEditorOpen});
    this.changeNewStatus = (event) => this.setState({newStatus: event.target.value});
  }

  async getOrderProducts() {
    let productData = await axios.get('order/get_order', {
      params: {
        seller_id: global.appConfig.companyID,
        order_id: this.props.id
      }
    });

    let results = [];

    productData.data.products.forEach(item => {
      const info = item.info;
      const quantity = item.quantity;
      const discounts = item.discounts;

      results.push({
        id: info.id,
        code: info.code,
        name: info.name,
        price: info.price,
        currency: info.currency,

        quantity: quantity,
        discounts: discounts
      });
    });

    this.setState({ productData: results });
  }

  Status(orderStatus) {
    if (orderStatus === "PLACED") {
        return <Badge color="info">Neprocesată</Badge>;
    }
    if (orderStatus === "PROCESSING") {
        return <Badge color="warning">În lucru</Badge>;
    }
    if (orderStatus === "EXPEDITED") {
        return <Badge color="success">Expediată</Badge>;
    }
    if (orderStatus === "CANCELLED") {
        return <Badge color="danger">Anulată</Badge>;
    }

    return <Badge>Necunoscută</Badge>;
  }

  render() {
    let { id, company_name, status, date, billed_price, address } = this.props;

    return (
      <Card outline color="primary" className="mb-2">
        <CardBody>
          <CardTitle className="h2"><Badge>Comanda #{id}</Badge> {this.Status(status)}</CardTitle>
          <Row>
            <Col sm="6">
              <CardText>
                Plasată de: <strong>{company_name}</strong><br />
                Plasată la: <span>{date}</span><br />
                Valoare: <span style={{ color: "red", fontSize: "1.2em" }}>{parseFloat(billed_price).toFixed(2)} RON</span>
              </CardText>
            </Col>
            <Col sm="6">
              <CardText>
                <strong>Adresă de livrare:</strong><br />
                <span>{address.street} {address.street_no},</span><br />
                { address.other && (<span>{address.other},<br /></span>) }
                <span>{address.city}, {address.county},</span><br />
                <span>C.P. {address.postal_code}</span><br />
              </CardText>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
            <Link to={`/order/${id}`}>
              <Button color="primary">Detalii comandă</Button>
            </Link>
            <Button onClick={this.toggleSummary} className="ml-2" color="info">Vezi produse</Button>
            {
              ((status === "PLACED" || status === "PROCESSING") && !this.props.editable) && (
                <span>
                  <Button color="danger" className="ml-2" 
                    onClick={() => this.setState({newStatus: "CANCELLED"}, this.toggleStatusEditor)}
                  >
                    Anulează
                  </Button>
                  <OrderStatusEditor isOpen={this.state.statusEditorOpen} toggle={this.toggleStatusEditor} onUpdate={this.handleOnUpdate} orderID={id} oldStatus={status} newStatus={this.state.newStatus} />
                </span>
              )
            }
            {(this.props.editable) && (
              <div className="form-inline ml-3" style={{display: "inline"}}>
                <Input defaultValue={status} type="select" onChange={this.changeNewStatus}>
                  <option value="PLACED">Neprocesată</option>
                  <option value="PROCESSING">În lucru</option>
                  <option value="EXPEDITED">Expediată</option>
                  <option value="CANCELLED">Anulată</option>
                </Input>
                {
                  this.state.newStatus !== status && (
                    <span>
                      <Button className="ml-2" color="warning" onClick={this.toggleStatusEditor}>Editează starea</Button>
                      <OrderStatusEditor isOpen={this.state.statusEditorOpen} toggle={this.toggleStatusEditor} onUpdate={this.handleOnUpdate} orderID={id} oldStatus={status} newStatus={this.state.newStatus} />
                    </span>
                  )
                }
              </div>
            )}
          </CardFooter>
        <Modal fade={false} isOpen={this.state.summaryOpen && this.state.summaryLoaded} toggle={this.toggleSummary} size="lg">
          <ModalHeader toggle={this.toggleSummary}>Produse în comanda #{id}</ModalHeader>
          <ModalBody>
            <OrderSummary products={this.state.productData} size="sm" />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleSummary}>Închide</Button>
          </ModalFooter>
        </Modal>
      </Card>
    );
  }
}

export default Order;
