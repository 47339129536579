import React, { Component } from "react";
import { ListGroup, ListGroupItem, Row, Col, Button, Input, InputGroup, InputGroupAddon } from "reactstrap";

import {Form, Field} from 'simple-react-form'
import SimpleReactValidator from 'simple-react-validator';
import appContext from "../../context/appContext";
import axios from "axios";


class FieldEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldValue: props.initialValue,
      fieldState: {},
      isEditing: false,
      isWaiting: false,

      error: undefined,
    };
    this.state.fieldState[props.fieldID] = props.initialValue;

    this.validator = new SimpleReactValidator({
      element: (message) => <div className='invalid-feedback' style={{display: "initial"}}>{message}</div>,
    });
  }

  toggleEditor = () => {
    this.setState({isEditing: !this.state.isEditing});
  };

  onSave = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
      return;
    }

    if(this.props.save) {
      this.setState({isWaiting: true}, () => {
        this.props.save(this.props.fieldID, this.state.fieldState[this.props.fieldID])
          .then(() => {
            this.toggleEditor();
            this.setState({error: undefined, isWaiting: false, fieldValue: this.state.fieldState[this.props.fieldID]});
            if(this.props.onSaveSuccess) {
              this.props.onSaveSuccess(this.state.fieldState[this.props.fieldID]);
            }
          })
          .catch(error => {
            console.log(error);
            this.setState({error: true, isWaiting: false});
          })
      });
    } else {
      this.toggleEditor();
    }
  }

  textInput(props) {
    return (
      <Input defaultValue={props.value} type={props.contentType} onChange={event => props.onChange(event.target.value)} placeholder={props.placeholder} invalid={props.invalid}/>
    );
  }

  render() {
    if(this.state.isWaiting) {
      return (
        <ListGroupItem className="d-flex justify-content-between">
          <span className="my-auto mr-auto"><strong>{this.props.fieldName}</strong></span>
          <div>Încărcare...</div>
        </ListGroupItem>
      );
    }

    if(this.state.error) {
      return (
        <ListGroupItem className="d-flex justify-content-between">
          <span className="my-auto mr-auto"><strong>{this.props.fieldName}</strong></span>
          <span style={{color: "red"}}>A fost întâmpinată o eroare!</span>
        </ListGroupItem>
      );
    }

    return (
      <ListGroupItem className="d-flex justify-content-between">
        <span className="my-auto mr-auto"><strong>{this.props.fieldName}</strong></span>
        {!this.state.isEditing ? (
          <div>
            <span className="my-auto">{this.state.fieldValue ? this.state.fieldValue : "(Gol)"}</span>
            <Button className="ml-2" color="info" onClick={this.toggleEditor}>Editează</Button>
          </div>
        ) : (
          <div>
            <Form state={this.state.fieldState} onChange={state => this.setState({fieldState: state})}>
              <InputGroup>
                <Field contentType="text" fieldName={this.props.fieldID} type={this.textInput} placeholder={this.props.fieldName} value={this.state.fieldValue} />
                <InputGroupAddon addonType="append"><Button color="success" onClick={this.onSave}>Salvează</Button></InputGroupAddon>
                <InputGroupAddon addonType="append"><Button color="danger" onClick={this.toggleEditor}>Anulează</Button></InputGroupAddon>
              </InputGroup>
              {this.validator.message(this.props.fieldID, this.state.fieldState[this.props.fieldID], this.props.validationRules)}
            </Form>
          </div>
        )}
      </ListGroupItem>
    );
  }
}

class Page extends Component {
  async save(fieldID, newName) {
    return await axios.post('user/update_setting', {
      field_name: fieldID,
      field_value: newName,
    });
  }

  onSuccess = newName => {
    console.log(newName);
    this.context.refreshApp();
  }

  render() {
    let {userData} = this.context;

    return (
      <div>
        <h1>Informații de contact</h1>
        <p>Editați informațiile personale asociate acestui cont</p>

        <hr />

        <Row>
          <Col md="10">
            <ListGroup>
              <FieldEditor fieldID="name" fieldName="Nume și prenume" initialValue={userData.name} save={this.save} onSaveSuccess={this.onSuccess} validationRules='required' />
              <FieldEditor fieldID="notifications_email" fieldName="Email notificări" initialValue={userData.notifications_email} save={this.save} onSaveSuccess={this.onSuccess} validationRules='email' />
            </ListGroup>
          </Col>
        </Row>
      </div>
    );
  }
}
Page.contextType = appContext;

export default Page;
