import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import OrderManager from "../../components/orderManager";
import appContext from "../../context/appContext";

class OrdersPage extends Component {
  render() {
    return (
      <div>
        <h1 className="align-middle">
          <Link to="../">
            <Button color="primary">
              <FontAwesomeIcon icon="long-arrow-alt-left" />
            </Button>
          </Link>
          &nbsp;Comenzile mele
        </h1>
        <OrderManager pageSize={10} layout="wide" companyID={this.context.userData.company_id} />
      </div>
    );
  }
}
OrdersPage.contextType = appContext;

export default OrdersPage;
