import React, { Component } from "react";
import { Switch, NavLink } from "react-router-dom";
import PrivateRoute from "../../components/privateRoute";
import * as perm from "../../helpers/permissionsHelper";


import {
  ListGroup,
  Badge,
  Row, Col
} from "reactstrap";

import pages from "./main";
import appContext from "../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class IndexPage extends Component {
  render() {
    return (
      <div>
        <h1>Contul meu</h1>
        <Row>
          <Col className="mb-3" lg="3">
            <ListGroup>
              <NavLink to="/account" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                <span><FontAwesomeIcon icon="home" className="mr-2" />Acasă</span>
              </NavLink>
            </ListGroup>

            <h5 className="mt-2 mb-2">Setări cont</h5>
            <ListGroup>
              <NavLink to="/account/contact-info" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                <span><FontAwesomeIcon icon="id-card" className="mr-2" />Informații de contact</span>
              </NavLink>
              <NavLink to="/account/email-change" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                <span><FontAwesomeIcon icon="envelope" className="mr-2" />Schimbare e-mail</span>
              </NavLink>
              <NavLink to="/account/password-change" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                <span><FontAwesomeIcon icon="key" className="mr-2" />Schimbare parolă</span>
              </NavLink>
            </ListGroup>
            {/*
            <h5 className="mt-2 mb-2">Notificări</h5>
            <ListGroup>
              <NavLink to="/account/notifications" exact activeClassName='active' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                <span><FontAwesomeIcon icon="rss" className="mr-2" />Mesaje promoționale</span>
              </NavLink>
            </ListGroup>*/}
          </Col>
          <Col lg="9">
            <Switch>
              <PrivateRoute exact path="/account" component={pages.home} />
              <PrivateRoute exact path="/account/contact-info" component={pages.contactInfo} />
              <PrivateRoute exact path="/account/email-change" component={pages.emailChange} />
              <PrivateRoute exact path="/account/password-change" component={pages.passwordChange} />
              <PrivateRoute exact path="/account/notifications" component={pages.notifications} />
            </Switch>
          </Col>
        </Row>
      </div>
    );
  }
}
IndexPage.contextType = appContext;

export default IndexPage;
