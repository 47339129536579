import React, { Component } from "react";
import axios from "axios";
import { Form, Field } from 'simple-react-form'
import SimpleReactValidator from 'simple-react-validator';

import {
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Input, Alert,
    FormGroup, Label, Col,
} from "reactstrap";

export default class CompanyBanEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,
            formState: {
                banReason: null,
            },

            isBanned: null,

            isOpen: false,
        }

        this.save = () => {
            if (!this.state.isBanned && !this.validator.allValid()) {
                this.validator.showMessages();
                // rerender to show messages for the first time
                this.forceUpdate();

                return;
            }

            this.setState({loading: true});

            this.saveBanStatus()
                .then(() => {
                    this.setState({loading: false}, this.toggle());
                })
                .catch(error => {
                    console.log(error);
                    this.setState({error: true});
                });
        }

        this.toggle = () => {
            if(this.state.isOpen) {
                this.setState({isOpen: false});
                return;
            }
            
            axios.post("company/get_ban_status", {
                company_id: this.props.companyID,
            }).then(response => {
                let {data} = response;

                let formState = this.state.formState;
                formState.banReason = data.reason;

                this.setState({
                    isOpen: true,
                    isBanned: data.is_banned,
                    formState: formState,
                });
            }).catch(error => {

            });
        }

        this.validator = new SimpleReactValidator({
            element: (message) => <div className='invalid-feedback' style={{ display: "initial" }}>{message}</div>
        });
    }

    async saveBanStatus() {   
        await axios.post("company/set_ban_status", {
            company_id: this.props.companyID,
            ban_status: !this.state.isBanned,
            reason: this.state.formState.banReason
        });
    }

    textInput(props) {
        return (
            <Input defaultValue={props.defaultValue} type={props.contentType} onChange={event => props.onChange(event.target.value)} placeholder={props.placeholder} invalid={props.invalid} id={props.id} />
        );
    }

    renderModal() {
        return (
            <Modal fade={false} isOpen={this.state.isOpen}>
                <ModalHeader toggle={this.toggle}>Accesul companiei <b>{this.props.companyName}</b></ModalHeader>
                <ModalBody>
                    {this.state.error && <Alert color="danger">A intervenit o eroare la salvarea datelor!</Alert> }

                    {this.state.isBanned ? (
                        <Alert color="danger">
                            <p>Compania <b>{this.props.companyName}</b> este blocată.</p>
                            <hr />
                            <h5>Motivul blocării</h5>
                            <p>{this.state.formState.banReason}</p>
                        </Alert> 
                    ) : (
                        <div>
                            <Alert color="success">Compania {this.props.companyName} nu este momentan blocată.</Alert>
                            <Form state={this.state.formState} onChange={state => this.setState({ formState: state })}>
                                <FormGroup>
                                    <Label>Motiv blocare</Label>
                                    <Field contentType="text" fieldName="banReason" type={this.textInput} placeholder="ex. Neplată factură" defaultValue={this.state.formState.banReason} />
                                    {this.validator.message('banReason', this.state.formState.banReason, 'required')}
                                </FormGroup>
                            </Form>
                        </div>
                    )}

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.toggle}>Închide</Button>
                    <Button color="danger" onClick={this.save}>{this.state.isBanned ? "Deblocare" : "Blocare" }</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        return (
            <div>
                <Button color="info" size="sm" onClick={this.toggle}>Vezi</Button>
                {this.renderModal()}
            </div>
        );
    }
}