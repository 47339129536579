import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  Button,
} from "reactstrap";

import Product from "../../../components/product";
import axios from "axios";

import appContext from '../../../context/appContext';

class BasketView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      productsLoaded: false
    };

    this.removeProduct = this.removeProduct.bind(this);
  }

  async getProducts() {
    try {
      let response = await axios.get("basket/get_contents");

      let data = response.data;
      let results = [];

      data.products.forEach(item => {
        const info = item.info;
        const quantity = item.quantity;
        const discounts = item.discounts;

        results.push({
          id:               info.id,
          code:             info.code,
          oldCode:          info.old_code,
          name:             info.name,
          manufacturerName: info.manufacturer_name,
          price:            info.price,
          currency:         info.currency,
          stock:            info.stock_cache,
          isSpecial:        item.is_special,

          quantity:         quantity,
          discounts:        discounts,
        });
      });

      this.context.updateBasketCount(results.length);

      this.setState({
        products: results
      });
    } catch (error) {
      // handle error
      console.log(error);
    }
  }

  removeProduct(productID) {
    axios.get("basket/remove", {
      params: {
        product_id: productID
      }
    }).then(() => {
      this.getProducts();
    }).catch(error => {
      console.log(error);
    })
  }

  componentDidMount() {
    this.getProducts().then(() => {
      this.setState({
        productsLoaded: true
      });
    });
  }

  render() {
    let products = null;

    if (this.state.productsLoaded) {
      products = (
        <div>
          {this.state.products.length === 0 && (
            <div>
              <p>Coșul dumneavoastră este gol</p>
              <div className="text-center m-3">
                <img className="img-fluid" src="empty-cart.png" alt="Empty basket" />
              </div>
            </div>
          )}

          {this.state.products.map((item, i) => (
            <Product
              key={i}
              {...item}
              layout="basket"
              removeProductCallback={this.removeProduct}
            />
          ))}
        </div>
      );
    }

    return (
      <div>
        {products}
        {this.state.products.length !== 0 && (
            <div>
              <hr />
              <h2>Plasează comanda</h2>
              <p>Dacă ai terminat cumpărăturile, atunci e timpul să stabilim detaliile de livrare și plată.</p>
              <Link to="/basket/order">
                <Button color="success">Continuă</Button>
              </Link>
            </div>
          )}
      </div>
    );
  }
}
BasketView.contextType = appContext;

export default BasketView;