import React, { Component } from "react";
import OrderManager from "../../components/orderManager";

class IndexPage extends Component {
  render() {
    return (
      <div>
        <h1>Comenzi {/*<Badge color="danger">180</Badge>*/}</h1>
        <OrderManager pageSize={10} editable={true} />
      </div>
    );
  }
}

export default IndexPage;
