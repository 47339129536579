import React, { Component } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Button,
  CardFooter,
  ListGroup,
  ListGroupItem,
  InputGroup,
  Input,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import appContext from '../context/appContext';

class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedQuantity: 1,
      modal: false,
      updatingQuantity: false,
    };

    if(this.props.quantity)
      this.state.selectedQuantity = this.props.quantity;

    this.toggleModal = this.toggleModal.bind(this);
    this.updateDisplayPrice = this.updateDisplayPrice.bind(this);
    this.addToBasket = this.addToBasket.bind(this);
    this.updateQuantity = this.updateQuantity.bind(this);
    this.removeProduct = this.removeProduct.bind(this);

  }
  
  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }

  updateDisplayPrice(event) {
    this.setState({ selectedQuantity: Math.max(1, event.target.value) });
  }

  addToBasket() {
    axios.get("basket/add", { params: {
      product_id: this.props.id,
      quantity: this.state.selectedQuantity
    }}).then(() => {
      this.context.updateBasketCount(this.context.basketCount + 1);
      this.setState({modal: true});
    }).catch((error) => {
      console.log(error);
    });
  }

  updateQuantity() {
    this.setState({
      updatingQuantity: true
    });

    axios.get("basket/change_quantity", {
      params: {
        company_id: global.appConfig.companyID,
        product_id: this.props.id,
        quantity: this.state.selectedQuantity
      }
    }).then(() => {
      this.setState({
        updatingQuantity: false
      });
    });
  }

  removeProduct() {
    this.props.removeProductCallback(this.props.id);
  }

  render() {
    let unitPrice = parseFloat(this.props.price);

    let initialPrice = null;
    let discountPercent = null;
    if(this.props.discounts) {
      this.props.discounts.forEach((discount) => {
        if(discount.type === "percentage") {
          unitPrice = unitPrice + parseFloat(discount.value) / 100 * this.props.price;
        }
      });

      initialPrice = (this.state.selectedQuantity * this.props.price).toFixed(2);
      discountPercent = -Math.round(100 - (unitPrice / this.props.price) * 100);
    }

    const selectedPrice = (this.state.selectedQuantity * unitPrice).toFixed(2);

    let Footer;
    
    if(this.props.layout === "basket") {
      let UpdateButton;
      if(this.state.updatingQuantity) {
        UpdateButton = (
          <Button onClick={this.updateQuantity} color="success" block disabled>
            <FontAwesomeIcon className="mr-2" icon="circle-notch" spin />
            Actualizare...
          </Button>
        );
      } else {
        UpdateButton = (
          <Button onClick={this.updateQuantity} color="success" block>
            <FontAwesomeIcon className="mr-2" icon="pencil-alt" />
            Schimbă cantitatea
          </Button>
        );
      }

      Footer = (
        <CardFooter className="text-muted">
          <Row className="d-flex justify-content-end">
            <Col xs="3" lg="1">
              <InputGroup>
                <Input placeholder="#" type="number" min="1" step="1" defaultValue={this.state.selectedQuantity} onChange={this.updateDisplayPrice} />
              </InputGroup>
            </Col>
            <Col xs="9" lg="3">
              {UpdateButton}
            </Col>
            <Col xs="9" lg="2">
              <Button onClick={this.removeProduct} color="danger" block>
                <FontAwesomeIcon className="mr-2" icon="trash-alt" />
                Elimină
              </Button>
            </Col>
          </Row>
        </CardFooter>
      );
    } else if(this.props.layout === "offer") {
      Footer = (
        <CardFooter className="text-muted">
          <Row className="d-flex justify-content-end">
            <Col xs="3" lg="2">
              <InputGroup>
                <Input placeholder="#" type="number" min="1" step="1" defaultValue={this.state.selectedQuantity} onChange={this.updateDisplayPrice} />
              </InputGroup>
            </Col>
            <Col xs="9" lg="3">
              <Button onClick={() => {this.props.selectCallback({product: this.props, count: this.state.selectedQuantity})}} color="primary" block>Adaugă</Button>
            </Col>
          </Row>
        </CardFooter>
      );
    } else {
      Footer = (
        <CardFooter className="text-muted">
          <Row className="d-flex justify-content-end">
            <Col xs="3" lg="1">
              <InputGroup>
                <Input placeholder="#" type="number" min="1" step="1" defaultValue={this.state.selectedQuantity} onChange={this.updateDisplayPrice} />
              </InputGroup>
            </Col>
            <Col xs="9" lg="3">
              <Button onClick={this.addToBasket} color="primary" block>
                <FontAwesomeIcon className="mr-2" icon="shopping-cart" />
                Adaugă în coș
              </Button>
            </Col>
          </Row>
        </CardFooter>
      );
    }

    return (
      <Card className={(this.props.layout === "offer") ? "mb-2" : "mb-2 ml-lg-5 mr-lg-5"}>
        <CardHeader className="d-flex justify-content-between" tag="h3">
          <div className="text-truncate">
            <span>{this.props.name ? this.props.name : "(Fără denumire)"}</span>
          </div>
          <Badge color="primary">
            #{this.props.code}
          </Badge>
          </CardHeader>
        <CardBody>
          <Row>
            <Col lg="8">
              <ListGroup>
                {
                  this.props.oldCode && (
                    <ListGroupItem>
                      <Badge className="mr-2" color="dark">Cod vechi:</Badge>
                      <Badge color="secondary">
                        #{this.props.oldCode}
                      </Badge>
                    </ListGroupItem>
                  )
                }
                <ListGroupItem>
                  <Badge className="mr-2" color="dark">Producător:</Badge>
                  {this.props.manufacturerName}
                </ListGroupItem>
                <ListGroupItem>
                  <Badge className="mr-2" color="danger">Cantitate în stoc:</Badge>
                  <span style={{color: "red"}}>{parseFloat(this.props.stock).toFixed(2)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <Badge className="mr-2" color="dark">Disponibil:</Badge>
                  4-6 zile
                </ListGroupItem>
              </ListGroup>
            </Col>
            <Col lg="4" className="d-flex justify-content-center">
              {(this.props.discounts && this.props.discounts.length > 0) ? (
                <div className="price text-center align-self-center">
                  <p style={{ margin: 0 }}>
                    <span style={{ textDecoration: "line-through" }}>{initialPrice} {this.props.currency}</span>{" "}
                    <span style={{ color: "grey" }}>({discountPercent}%)</span>
                  </p>
                  <p style={{ fontWeight: "bold", color: "red", margin: "0" }}>
                    <span style={{ fontSize: "2em" }}>{selectedPrice}</span> {this.props.currency}
                  </p>
                  <p style={{ color: "grey" }}>
                    (Preț pentru {this.state.selectedQuantity} buc.)
                  </p>
                  <p style={{ color: "grey", fontSize: "0.65em", margin: "0" }}>
                    Prețul afișat nu conține TVA
                  </p>
                </div>
              ) : (
                <div className="price text-center align-self-center">
                  <p style={{ fontWeight: "bold", color: "red", margin: "0" }}>
                    <span style={{ fontSize: "2em" }}>{selectedPrice}</span> {this.props.currency}
                  </p>
                  <p style={{ color: "grey", }}>
                    (Preț pentru {this.state.selectedQuantity} buc.)
                  </p>
                  <p style={{ color: "grey", fontSize: "0.65em", margin: "0" }}>
                    Prețul afișat nu conține TVA
                  </p>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
        
        {Footer}

        <Modal fade={false} isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggle}>Produs adăugat</ModalHeader>
          <ModalBody>
            Ai adăugat {this.state.selectedQuantity} {this.state.selectedQuantity == 1 ? "bucată" : "bucăți"} de {!this.props.name ? `#${this.props.code}` : this.props.name} în coșul tău.
          </ModalBody>
          <ModalFooter>
            <Link to="/basket"><Button color="secondary" className="mr-2">Vezi coșul</Button></Link>
            <Button color="primary" onClick={this.toggleModal}>Continuă cumpărăturile</Button>
          </ModalFooter>
        </Modal>
      </Card>
    );
  }
}
Product.contextType = appContext;

export default Product;
