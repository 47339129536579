import React, { Component } from "react";

import {
  Button,
  Row,
  Col,
  Input
} from "reactstrap";

import AddressPicker from "../../../components/addressPicker";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import appContext from '../../../context/appContext';
import OrderSummary from "../../../components/orderSummary";

class BasketOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      productsLoaded: false,
      addressID: null,
      mentions: null,
    };

    this.removeProduct = this.removeProduct.bind(this);
    this.placeOrder = this.placeOrder.bind(this);

    this.updateAddress = (addressID) => {
        this.setState({addressID: addressID});
    };
  }

  async getProducts() {
    try {
      let response = await axios.get("basket/get_contents");

      let data = response.data;
      let results = [];

      data.products.forEach(item => {
        const info      = item.info;
        const quantity  = item.quantity;
        const discounts = item.discounts;

        results.push({
          id:       info.id,
          code:     info.code,
          name:     info.name,
          price:    info.price,
          currency: info.currency,

          quantity: quantity,
          discounts: discounts
        });
      });

      this.context.updateBasketCount(results.length);

      this.setState({
        products: results,
      });
    } catch (error) {
      // handle error
      console.log(error);
    }
  }

  removeProduct(productID) {
    axios.get("basket/remove", {
      params: {
        product_id: productID
      }
    }).then(() => {
      this.getProducts();
    }).catch(error => {
      console.log(error);
    })
  }

  placeOrder() {
    axios.post("basket/place", {
      address_id: this.state.addressID,
      mentions: this.state.mentions,
    }).then((data) => {
      let responseData = data.data;
      this.getProducts();
      this.context.updateBasketCount(0);
      
      this.props.history.push(`/order/${responseData.order_id}`);
    }).catch(error => {
      console.log(error);
    })
  }

  componentDidMount() {
    this.getProducts().then(() => {
      this.setState({
        productsLoaded: true
      });
    });
  }

  render() {
    if (!this.state.productsLoaded) {
        return (
            <p>Încărcare...</p>
        );
    }

    if(!this.state.products.length) {
        return (
            <p>Comanda nu conține niciun produs!</p>
        )
    }

    let products = (
        <div>
            {this.state.products.length === 0 && (
                <p>Coșul dumneavoastră este gol</p>
            )}
            <OrderSummary products={this.state.products} />
        </div>
    );

    return (
      <div>
        <h2>Produse în comandă</h2>
        {products}
        
        <hr />

        <h2>Detalii despre livrare</h2>
        <Row>
            <Col md="6">
                <h3>Adresă</h3>
                <AddressPicker onSelect={this.updateAddress} />
            </Col>
            <Col md="6">
                <h3>Mențiuni</h3>
                <Input type="textarea" rows={5} onChange={event => this.setState({mentions: event.target.value})} />
            </Col>
        </Row>
        
        <hr />

        <h2>Finalizare</h2>
        <p>Apasă butonul de mai jos pentru a trimite comanda.</p>
        <Button color="success" onClick={this.placeOrder}><FontAwesomeIcon icon="handshake" /> Plasează comanda</Button>
      </div>
    );
  }
}
BasketOrder.contextType = appContext;

export default BasketOrder;