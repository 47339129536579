import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  Badge,
  Progress,
  Button,
  Card,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardHeader,
  CardBody,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Alert
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axios from 'axios';

import appContext from '../../context/appContext';
import Offer from "../../components/offer";

class DealsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      sales: null,
      currentTierInfo: null,
      nextTierInfo: null,
      showDiscounts: true,
      offerData: [],
    };
  }

  async getOffers() {
    let offerResult = await axios.get('offer/get_active');
    return offerResult.data.offers;
  }

  refreshOffers() {
    this.getOffers()
      .then(offerData => this.setState({offerData: offerData}))
      .catch(error => console.log(error));
  }

  async getDiscounts() {
    let discountInfoReply = await axios.get('company/discount_stats', {
      params: {
        company_id: global.appConfig.companyID,
      }
    });

    let discountInfo = discountInfoReply.data;
    this.setState({
      lastMonthSales:   discountInfo.last_month_sales,
      thisMonthSales:   discountInfo.this_month_sales,
      currentTierInfo:  discountInfo.current_tier_info,
      nextTierInfo:     discountInfo.next_tier_info
    });
  }

  componentDidMount() {
    this.getDiscounts()
      .then(() => {
        this.setState({isLoaded: true});
      })
      .catch((error) => {
        console.log(error);
        this.setState({isLoaded: true, showDiscounts: false});
      });
    this.refreshOffers();
  }

  render() {
    if(!this.state.isLoaded) {
      return (
        <h1>Încărcare...</h1>
      );
    }

    let Discounts = null;
    if(this.state.showDiscounts) {
      Discounts = (
        <div>
          <h2>Program de fidelitate</h2>

          <Row>
            <Col lg="3">
              <Card className="text-center" body>
                <CardTitle style={{ color: "blue", fontSize: "5em" }}>
                  <FontAwesomeIcon icon="building" />
                </CardTitle>
                <appContext.Consumer>
                  {(state) => <CardSubtitle>{state.userCompanyData.name}</CardSubtitle>}
                </appContext.Consumer>
                {this.state.currentTierInfo !== null && <CardText>Client {this.state.currentTierInfo.name}</CardText>}
                {this.state.nextTierInfo != null && (
                  <div>
                    <small>Plasați comenzi de minim {(+this.state.nextTierInfo.sales_min).toFixed(2)} RON luna aceasta pentru a atinge următorul prag</small>
                  </div>
                )}
              </Card>
            </Col>
            {this.state.currentTierInfo !== null && (
              <Col lg="3">
                <h3>Reduceri curente</h3>
                <ListGroup>
                  <ListGroupItem className="d-flex justify-content-between align-items-center">
                    Categoria I
                    <Badge color="danger" pill>
                      {this.state.currentTierInfo.category_1_discount}%
                    </Badge>
                  </ListGroupItem>
                  <ListGroupItem className="d-flex justify-content-between align-items-center">
                    Categoria a II-a
                    <Badge color="danger" pill>
                      {this.state.currentTierInfo.category_2_discount}%
                    </Badge>
                  </ListGroupItem>
                  <ListGroupItem className="d-flex justify-content-between align-items-center">
                    Categoria a III-a
                    <Badge color="danger" pill>
                      {this.state.currentTierInfo.category_3_discount}%
                    </Badge>
                  </ListGroupItem>
                </ListGroup>
              </Col>
            )}
            
            <Col lg="6">
              <h3>
                Detalii
              </h3>
              {this.state.nextTierInfo != null && (
                <Alert color="success" fade={false}>
                  {(this.state.nextTierInfo.sales_min - this.state.thisMonthSales > 0) ? (
                    <span>Efectuați comenzi de {(this.state.nextTierInfo.sales_min - this.state.thisMonthSales).toFixed(2)} RON
                    pentru a deveni client {this.state.nextTierInfo.name} și beneficia de reduceri și mai avantajoase!</span>
                  ) : (
                    <span>Luna viitoare veți deveni client {this.state.nextTierInfo.name}!</span>
                  )}

                  <h6>Progres: {Math.min(100, Math.floor(this.state.thisMonthSales / this.state.nextTierInfo.sales_min * 100))}%</h6>
                  <Progress
                      value={this.state.thisMonthSales}
                      max={this.state.nextTierInfo.sales_min}
                  />
                </Alert>
              )}
              {this.state.currentTierInfo != null && (
                <Alert color="info" fade={false}>
                  {(this.state.currentTierInfo.sales_min - this.state.thisMonthSales > 0) ? (
                    <span>Efectuați comenzi de {(this.state.currentTierInfo.sales_min - this.state.thisMonthSales).toFixed(2)} RON pentru a rămâne client {this.state.currentTierInfo.name}!</span>
                  ) : (
                    <span>Luna viitoare veți rămâne client {this.state.currentTierInfo.name}!</span>
                  )}
                  
                  <h6>Progres: {Math.min(100, Math.floor(this.state.thisMonthSales / this.state.currentTierInfo.sales_min * 100))}%</h6>
                  <Progress
                      value={Math.min(100, Math.floor(this.state.thisMonthSales / this.state.currentTierInfo.sales_min * 100))}
                      max={100}
                  />
                </Alert>
              )}

              <CardDeck>
                <Card className="text-center" body>
                  <CardTitle
                    className="text-success"
                    style={{ fontSize: "2em" }}
                  >
                    {parseFloat(this.state.lastMonthSales).toFixed(2)} RON
                  </CardTitle>
                  <CardSubtitle><strong>Luna trecută</strong></CardSubtitle>
                  <CardText>
                    Totalul comenzilor efectuate luna trecută
                  </CardText>
                </Card>
                <Card className="text-center" body>
                  <CardTitle
                    className="text-primary"
                    style={{ fontSize: "2em" }}
                  >
                    {parseFloat(this.state.thisMonthSales).toFixed(2)} RON
                  </CardTitle>
                  <CardSubtitle><strong>Luna aceasta</strong></CardSubtitle>
                  <CardText>
                    Totalul comenzilor efectuate luna aceasta
                  </CardText>
                </Card>
              </CardDeck>
            </Col>
          </Row>
        </div>
      );
    }

    
    return (
      <div>
        <h1 className="align-middle">
          <Link to="../">
            <Button color="primary">
              <FontAwesomeIcon icon="long-arrow-alt-left" />
            </Button>
          </Link>
          &nbsp;Oferte
        </h1>
        {Discounts}
        
        <div>
          <h2>Oferte</h2>
          {this.state.offerData.length === 0 && (
            <p>Nu există nicio ofertă curentă sau viitoare</p>
          )}
          {
            this.state.offerData.map((offer, index) => (
              <Offer key={index} {...offer} />
            ))
          }
        </div>
      </div>
    );
  }
}

export default DealsPage;
