import React, { Component } from "react";
import { Link, Switch } from "react-router-dom";

import {
  Button,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NewCompanyPage from './newCompany';
import PrivateRoute from "../../../components/privateRoute";

class UserPage extends Component {
  render() {
    return (
      <div>
        <h1 className="align-middle">
          <Link to="../">
            <Button color="primary">
              <FontAwesomeIcon icon="long-arrow-alt-left" />
            </Button>
          </Link>
          &nbsp;Contul meu
        </h1>
        <div>
          <Switch>
            <PrivateRoute path="/user/newcompany" component={NewCompanyPage}  />
          </Switch>
        </div>
      </div>
    );
  }
}

export default UserPage;
