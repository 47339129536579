import React, { Component } from "react";

import axios from "axios";

import {
  Badge,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";

import appContext from "../context/appContext";
import * as perm from '../helpers/permissionsHelper';

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      dropdownOpen: false,
    };

    this.toggle = this.toggle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.toggleDropdown = () => this.setState({dropdownOpen: !this.state.dropdownOpen});
  }

  handleLogout() {
    axios.get("user/logout").then(response => {
      this.props.userDataCallback(null);
    });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    let Right = (
      <appContext.Consumer>
        {appState => {
          if (appState.isAuthed) {
            return (
              <Nav className="ml-auto" navbar>
                {
                  this.context.userData.company_id && (
                    <NavItem>
                      <Link className="text-white nav-link" to="/basket">
                        Coșul meu &nbsp;
                        <Badge color="danger" pill>
                          <appContext.Consumer>
                            {appState => appState.basketCount}
                          </appContext.Consumer>
                        </Badge>
                      </Link>
                    </NavItem>
                  )
                }
                {
                  (this.context.userData.company_id && perm.hasEitherPerm(this.context.userPermissions, ['COMPANY_OPERATOR', 'COMPANY_ADMIN'])) && (
                    <NavItem>
                      <Link className="text-white nav-link" to="/admin">
                        Administrare {global.appConfig.companyName}
                      </Link>
                    </NavItem>
                  )
                }
                <Dropdown nav inNavbar isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                  <DropdownToggle className="text-white" nav caret>
                    <FontAwesomeIcon className="mr-1" icon="building" />
                    <appContext.Consumer>
                      {appState => appState.userData.name}
                    </appContext.Consumer>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <Link to="/account" className="d-flex justify-content-between dropdown-item" onClick={this.toggleDropdown}>
                      <FontAwesomeIcon className="my-auto" icon="user" />
                      Contul meu
                    </Link>
                    <DropdownItem divider />
                    <DropdownItem className="d-flex justify-content-between" onClick={this.handleLogout}>
                      <FontAwesomeIcon className="my-auto" icon="sign-out-alt" />
                      Deconectare
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Nav>
            );
          } else {
            return (
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <Link className="text-white nav-link" to="/login">
                    Autentificare
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="text-white nav-link" to="/register">
                    Înregistrare
                  </Link>
                </NavItem>
              </Nav>
            );
          }
        }}
      </appContext.Consumer>
    );

    return (
      <div>
        <Navbar style={{ backgroundColor: "#24405f" }} dark expand="md">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img src={`${global.appConfig.companyLogoURL}`} height="30" className="d-inline-block align-top" alt={`${global.appConfig.companyName}`} />
            </Link>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              {Right}
            </Collapse>
          </div>
        </Navbar>
      </div>
    );
  }
}
Menu.contextType = appContext;

export default Menu;
