import React, { Component } from "react";
import { Table, Button, ButtonGroup } from "reactstrap";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CompanyBanEditor from "../../components/companyBanEditor";

class PriceChangesVisibilityToggler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: null,
      loading: false,
    }

    if(props.isVisible) {
      this.state.isVisible = props.isVisible;
    }
  }

  async saveVisibility(isVisible) {
    let Result = await Axios.post('company/set_price_changes_visibility', {
      company_id: this.props.companyID,
      price_changes_visible: isVisible,
    });

    this.setState({isVisible: isVisible});
  }

  toggleVisibility = () => {
    this.setState({loading: true}, () => {
      this.saveVisibility(!this.state.isVisible)
        .then(() => {
          this.setState({loading: false});
        })
        .catch(error => console.log(error));
    });
  };
  
  render() {
    if(this.state.loading) {
      return <Button color="info" disabled  size="sm"><FontAwesomeIcon icon="spinner" spin /></Button>;
    }

    if(this.state.isVisible) {
      return <Button color="success" onClick={this.toggleVisibility} size="sm">Da</Button>;
    }

    return <Button color="danger" onClick={this.toggleVisibility} size="sm">Nu</Button>;
  }
}

export default class PartnersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      partners: [],
    };
  }

  async getPartners() {
    let partnerResponse = await Axios.get('/admin/get_partners_stats');
    this.setState({
      partners: partnerResponse.data.stats
    });
  }

  componentDidMount() {
    this.getPartners()
      .then(() => this.setState({isLoaded: true}))
      .catch(error => console.log(error));
  }

  render() {
    let {isLoaded, partners} = this.state;

    if(!isLoaded) {
      return (
        <div>Încărcare...</div>
      );
    }

    return (
      <div>
        <h1>Parteneri</h1>
        <Table bordered striped size={"sm"}>
          <thead>
            <tr>
              <th rowSpan="2">#</th>
              <th rowSpan="2">Nume</th>
              <th colSpan="2">Vânzări</th>
              <th rowSpan="2">Program fidelitate</th>
              <th rowSpan="2">Plafon</th>
              <th rowSpan="2">Modificări preț vizibile</th>
              <th rowSpan="2">Acces B2B</th>
            </tr>
            <tr>
              <th>Luna trecută</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {
              partners.map((partner, i) => (
                <tr key={i}>
                  <th scope="row">{i+1}</th>
                  <td>{partner.data.name}</td>
                  <td>
                    <span className="float-right">
                      {parseFloat(partner.last_month_sales).toFixed(2)} RON
                    </span>
                  </td>
                  <td>
                    <span className="float-right">
                      {parseFloat(partner.total_sales).toFixed(2)} RON
                    </span>
                  </td>
                  <td>{partner.discount_group_data.name}</td>
                  <td>{partner.discount_group_data.tier_name}</td>
                  <td><PriceChangesVisibilityToggler isVisible={partner.show_price_changes} companyID={partner.id} /></td>
                  <td><CompanyBanEditor companyID={partner.id} companyName={partner.data.name}/></td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
    );
  }
}
