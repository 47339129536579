export default {
    index: require('./index').default,
    search: require('./search').default,
    deals: require('./deals').default,
    order: require('./order').default,
    orders: require('./orders').default,
    basket: require('./basket/basket').default,
    login: require('./login').default,
    register: require('./register').default,
    user: require('./user/user').default,
    info: require('./info').default,
};