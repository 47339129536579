import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  Card,
  CardTitle,
  CardText,
  CardDeck,
  CardBody
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class IndexPage extends Component {
  render() {
    return (
      <div>
        <h1 style={{marginTop: "7px", marginBottom: "30px"}}>Cu ce te putem ajuta astăzi?</h1>
        <CardDeck>
          <Card className="card-button">
            <Link to="/search">
              <CardBody>
                <div className="feature-icon text-success">
                  <FontAwesomeIcon icon="search" />
                </div>
                <CardTitle style={{fontWeight: "600"}}>Căutare stoc</CardTitle>
                <CardText>Caută o piesă în stocul furnizorului</CardText>
              </CardBody>
            </Link>
          </Card>
          <Card className="card-button">
            <Link to="/deals">
              <CardBody>
                <div className="feature-icon text-danger">
                  <FontAwesomeIcon icon="percent" />
                </div>
                <CardTitle style={{fontWeight: "600"}}>Oferte</CardTitle>
                <CardText>Vezi ofertele pe care furnizorul ți le-a pus la dispoziție</CardText>
              </CardBody>
            </Link>
          </Card>
          <Card className="card-button">
            <Link to="/orders">
              <CardBody>
                <div className="feature-icon text-warning">
                  <FontAwesomeIcon icon="shopping-cart" />
                </div>
                <CardTitle style={{fontWeight: "600"}}>Istoric comenzi</CardTitle>
                <CardText>Vezi istoricul comenzilor plasate</CardText>
              </CardBody>
            </Link>
          </Card>
          <Card className="card-button">
            <Link to="/info">
              <CardBody>
                <div className="feature-icon text-primary">
                  <FontAwesomeIcon icon="info-circle" />
                </div>
                <CardTitle style={{fontWeight: "600"}}>Informații</CardTitle>
                <CardText>Vezi informațiile de contact ale furnizorului</CardText>
              </CardBody>
            </Link>
          </Card>
        </CardDeck>
      </div>
    );
  }
}

export default IndexPage;
