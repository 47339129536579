import React, { Component } from "react";
import AddressPicker from "../../components/addressPicker";

import {Form, Field} from 'simple-react-form'
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';

import {
  Input,
  Alert,
} from "reactstrap";


export default class SMSSettingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formState: {
        username: null,
        password: null,
        sender_name: null,
      },

      isLoading: true,
      success: undefined,
      error: undefined,
    };

    this.validator = new SimpleReactValidator({
      element: (message) => <div className='invalid-feedback' style={{display: "initial"}}>{message}</div>
    });
    
    this.OnSubmit = (event) => {
      event.preventDefault();
      
      if (this.validator.allValid()) {
        this.setState({isLoading: true});

        let {formState} = this.state;
        
        let Data = {
          whosms_username: formState.username,
          whosms_password: formState.password,
          whosms_sender_name: formState.sender_name,
        };
        
        axios.post('admin/set_sms_config', Data)
          .then(() => {
            this.setState({
              isLoading: false,
              success: true,
              error: undefined,
            });
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
              success: undefined,
              error: true,
            });
            console.log(error)
          });
      } else {
        this.validator.showMessages();
        // rerender to show messages for the first time
        this.forceUpdate();
      }
    };
  }

  textInput(props) {
    return (
        <Input defaultValue={props.value}  type={props.contentType} onChange={event => props.onChange(event.target.value)} placeholder={props.placeholder} invalid={props.invalid}/>
    );
  }

  async getInfo() {
    let smsConfig = await axios.get('admin/get_sms_config');
    let config = smsConfig.data.config;

    let formData = {
      username: config.whosms_username,
      password: config.whosms_password,
      sender_name: config.whosms_sender_name,
    };

    this.setState({formState: formData});
  }



  componentDidMount() {
    this.getInfo()
      .then(() => {
        this.setState({isLoading: false});
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    if(this.state.isLoading) {
      return (
        <h1>Încărcare...</h1>
      );
    }

    return (
      <div>
        <h1>Setări SMS</h1>
        <p>Setează informațiile de autentificare în WhoSMS</p>

        { 
          this.state.success && (
            <Alert color="success" fade={false}>Datele au fost actualizate cu succes!</Alert>
          )
        }

        { 
          this.state.error && (
            <Alert color="danger" fade={false}>Datele nu au putut fi actualizate!</Alert>
          )
        }

        <Form state={this.state.formState} onChange={state => this.setState({formState: state})}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Nume de utilizator</label>
              <Field contentType="text" fieldName="username" type={this.textInput} placeholder="Nume de utilizator" />
              {this.validator.message('email', this.state.formState.username, 'required')}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Parolă</label>
              <Field contentType="text" fieldName="password" type={this.textInput} placeholder="Parolă" />
              {this.validator.message('password', this.state.formState.password, 'required')}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Nume expeditor</label>
              <Field contentType="text" fieldName="sender_name" type={this.textInput} placeholder="Expeditor" />
              {this.validator.message('sender_name', this.state.formState.sender_name, 'required')}
            </div>
          </div>
          <button type="submit" className="btn btn-success" onClick={this.OnSubmit}>Salvează</button>
        </Form>
      </div>
    );
  }
}
