import React, { Component } from "react";
import AddressPicker from "../../components/addressPicker";
import { Link } from "react-router-dom";

import axios from 'axios';
import { Button, Form, FormGroup, Label, Input, InputGroupAddon, InputGroup } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Product from "../../components/product";

import CompanySearcher from "../../components/companySearcher";
import OrderSummary from "../../components/orderSummary";


export default class OfferCreatorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCompany: null,
      companySearcherActive: false,

      searchTerm: "",
      searchTermValid: true,
      searchResults: [],
      didSearch: false,
      isLoading: false,

      products: [],
    };

    this.toggleCompanySearcher = () => {
      this.setState({companySearcherActive: !this.state.companySearcherActive});
    }

    this.onCompanySelect = companyID => {
      this.setState({selectedCompany: companyID});
    }

    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchTermChange = this.handleSearchTermChange.bind(this);

    this.selectCallback = (data) => {
      let productArray = this.state.products;

      console.log(data.product);

      let currentIndex = productArray.findIndex(element => {
        console.log(element);
        return element.code == data.product.code;
      });

      if(currentIndex != -1) {
        productArray[currentIndex].quantity += data.count;
        console.log(productArray);
        this.setState({products: productArray});
        return;
      }

      let product = {
        code: data.product.code,
        currency: data.product.currency,
        discounts: data.product.discounts,
        id: data.product.id,
        isSpecial: data.product.isSpecial,
        manufacturerName: data.product.manufacturerName,
        name: data.product.name,
        oldCode: data.product.oldCode,
        price: data.product.price,
        stock: data.product.stock,
        quantity: data.count,
      }

      productArray.push(product);

      this.setState({products: productArray});
    }
  }

  handleSearchTermChange(event) {
    this.setState({ searchTerm: event.target.value });

    if (!event.target.value) {
      this.setState({ searchTermValid: false });
    }
  }

  handleSearch(event) {
    event.preventDefault();

    if (!this.state.searchTerm) {
      this.setState({ searchTermValid: false });
      return;
    }

    this.setState({
      searchTermValid: true,
      isLoading: true,
    });

    axios
      .get("product/search", {
        params: {
          search_string: this.state.searchTerm,
          search_as_company: this.state.selectedCompany
        }
      })
      .then(response => {
        let Data = response.data;
        let results = [];

        Data.results.forEach(item => {
          let info = item.info;

          results.push({
            id: info.id,
            code: info.code,
            oldCode: info.old_code,
            name: info.name,
            manufacturerName: info.manufacturer_name,
            price: info.price,
            currency: info.currency,
            stock: info.stock_cache,
            discounts: item.discounts,
            isSpecial: item.is_special,
          });
        });

        this.setState({
          searchResults: results,
          didSearch: true,
          isLoading: false,
        });
      })
      .catch(error => {
        // handle error
        console.log(error);
      });
  }

  renderSearch() {
      let searchResults;
  
      if (this.state.didSearch) {
        searchResults = (
          <div>
            <h2>Rezultatele căutării</h2>
            {this.state.searchResults.length === 0 && (
              <p>
                Nu a fost găsit niciun produs după termenul de căutare introdus
              </p>
            )}
  
            {this.state.searchResults.map((item, i) => (
              <Product
                key={i}
                layout="offer"
                {...item}
                selectCallback={this.selectCallback}
              />
            ))}
          </div>
        );
      }
  
      return (
        <div>
          <Form onSubmit={this.handleSearch}>
              <InputGroup>
              <Input
                type="text"
                name="partName"
                placeholder="Introdu ID-ul piesei"
                onChange={this.handleSearchTermChange}
                invalid={!this.state.searchTermValid}
              />
                <InputGroupAddon addonType="append"><Button color="success">Caută</Button></InputGroupAddon>
              </InputGroup>
          </Form>
  
          <hr />
  
          {this.state.isLoading && <p>Căutare...</p>}
  
          {!this.state.isLoading && searchResults}
        </div>
      );
  }

  renderStep2() {
    return (
      <div>
        <h2>Pasul 2: Alege produsele</h2>

        <h3>Produse în ofertă</h3>
        <OrderSummary size="sm" products={this.state.products}/>

        <h3>Căutare</h3>
        {this.renderSearch()}
      </div>
    )
  }

  renderStep1() {
    return (
      <div>
        <h2>Pasul 1: Alege compania</h2>
        <div>
          {this.state.selectedCompany ? <p>Ați selectat compania {this.state.selectedCompany}</p> : <p>Nicio companie selectată</p>}
          <Button color="success" onClick={this.toggleCompanySearcher}>Alege o companie</Button>
        </div>
        <CompanySearcher isOpen={this.state.companySearcherActive} toggle={this.toggleCompanySearcher} onPick={this.onCompanySelect} />
      </div>
    );
  }

  render() {
    return (
      <div>
        <h1>Zonă de ofertare</h1>
        <p>Caută produse în numele unei companii.</p>

        <hr />
        {!this.state.selectedCompany && this.renderStep1()}
        {this.state.selectedCompany && this.renderStep2()}
      </div>
    );
  }
}
