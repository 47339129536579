import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import {
  Button,
  Alert,
  Input,
  Row,
  Col,
  FormGroup,
  Label
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Field } from 'simple-react-form'
import SimpleReactValidator from 'simple-react-validator';

import axios from 'axios';
import AddressPicker from "../../../components/addressPicker";
import appContext from "../../../context/appContext";

class NewCompanyPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formState: {
        name: null,
        tax_id: null,
        trade_register_id: null,
      },
      registered_office_address_id: null,

      isLoading: false,
      success: undefined,
      error: undefined,
    };

    this.validator = new SimpleReactValidator({
      element: (message) => <div className='invalid-feedback' style={{ display: "initial" }}>{message}</div>
    });

    this.OnSubmit = (event) => {
      event.preventDefault();

      if (this.validator.allValid()) {
        this.setState({ isLoading: true });
        this.RegisterCompany()
          .then(() => {
            this.setState({
              isLoading: false,
              success: true,
              error: undefined,
            });

            this.context.refreshApp();
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
              success: undefined,
              error: true,
            });
            console.log(error)
          });
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }

    this.onSaveAddress = (addressID) => {
      this.setState({ registered_office_address_id: addressID });
    };

    this.toggleAddress = (event) => {
      if (event) event.preventDefault();
      this.setState({
        editorOpen: !this.state.editorOpen,
      });
    }
  }

  async RegisterCompany() {
    let { formState } = this.state;

    let LoginData = new FormData();
    LoginData.append("name", formState.name);
    LoginData.append("tax_id", formState.tax_id);
    LoginData.append("trade_register_id", formState.trade_register_id);
    LoginData.append("registered_office_address_id", this.state.registered_office_address_id);

    let registerResponse = await axios({
      method: "post",
      url: "company/create",
      data: LoginData,
      config: { headers: { "Content-Type": "multipart/form-data" } }
    });

    if (registerResponse.data.status === "success") {
      this.setState({
        success: true,
        error: undefined,
      });
    }
  }

  textInput(props) {
    return (
      <Input defaultValue={props.value} type={props.contentType} onChange={event => props.onChange(event.target.value)} placeholder={props.placeholder} invalid={props.invalid} />
    );
  }

  render() {
    if(this.context.userData.company_id) {
      return (
        <Redirect to="/" />
      );
    }

    return (
      <div>
        <h2>Companie nouă</h2>
        <Alert color="info">
          Momentan, contului dvs. nu îi este asociată nicio companie.<br />
          Prin formularul de mai jos, puteți înregistra o companie nouă, iar aceasta va fi asociată automat contului dv.
        </Alert>

        <Row>
          <Col lg="6">
            <Form state={this.state.formState} onChange={state => this.setState({ formState: state })}>
              <FormGroup>
                <Label><strong>Nume</strong></Label>
                <Field contentType="text" fieldName="name" type={this.textInput} placeholder="Numele companiei" />
                {this.validator.message('company_name', this.state.formState.name, 'required')}
              </FormGroup>
              <hr />
              <FormGroup>
                <Label><strong>CUI</strong></Label>
                <Field contentType="text" fieldName="tax_id" type={this.textInput} placeholder="CUI" />
                {this.validator.message('tax_id', this.state.formState.tax_id, 'required')}
              </FormGroup>
              <hr />
              <FormGroup>
                <Label><strong>Nr. Registrul Comerțului</strong></Label>
                <Field contentType="text" fieldName="trade_register_id" type={this.textInput} placeholder="Nr. Registrul Comerțului" />
                {this.validator.message('trade_register_id', this.state.formState.trade_register_id, 'required')}
              </FormGroup>
              <hr />
              <FormGroup>
                <Label><strong>Sediul social</strong></Label>
                <AddressPicker onSelect={this.onSaveAddress} />
                {this.validator.message('registered_office', this.state.registered_office_address_id, 'required')}
              </FormGroup>
              <hr />
              <Button color="success" block onClick={this.OnSubmit}>
                <FontAwesomeIcon icon="sign-in-alt" className="mr-2" />Înregistrare
              </Button>
            </Form>
          </Col>
        </Row>

      </div>
    );
  }
}
NewCompanyPage.contextType = appContext;

export default NewCompanyPage;
