import React, { Component } from "react";

import {
  Button,
  Badge,
  Card, CardHeader, CardBody, CardFooter, CardText
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OfferProductViewer from "./offerProductViewer";

export default class Offer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productViewerOpen: false,
    };
  }

  delete = (offerID) => {
    if(this.props.onDelete) {
      this.props.onDelete(offerID);
    }
  };

  toggleProductViewer = () => this.setState({productViewerOpen: !this.state.productViewerOpen});

  render() {
    let {props} = this;
    return (
      <Card className="mb-2">
        <CardHeader>{props.name}</CardHeader>
        <CardBody>
          <CardText>{props.description}</CardText>
          <hr />
          <Badge color="primary">Valid de la: {props.start_date}</Badge>
          <Badge className="ml-2" color="primary">Valid până la: {props.end_date}</Badge>
          <Badge className="ml-2" color="info">{props.discount_percentage}% reducere</Badge>
        </CardBody>
        <CardFooter>
          <Button color="info" onClick={this.toggleProductViewer}>Vezi produse</Button>
          {this.props.canDelete && (
            <Button className="ml-2" color="danger" onClick={() => this.delete(props.id)}>
              <FontAwesomeIcon icon="trash" /> Șterge oferta
            </Button>
          )}
        </CardFooter>

        <OfferProductViewer isOpen={this.state.productViewerOpen} toggle={this.toggleProductViewer} offerID={props.id} />
      </Card>
    )
  }
}