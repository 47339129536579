import React, { Component } from "react";

import axios from "axios";

import TierEditor from "./tierEditor";

import {
  Button,
  ListGroupItem, ListGroupItemHeading, ListGroupItemText,
  Table,
  Alert,
} from "reactstrap";

import CompanyMultiPicker from "./companyMultiPicker";

export default class DiscountGroupInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tiers: [],
      loaded: false,
      isDefault: null,
      editorActive: false,
      companyPickerActive: false,
      name: null
    }

    this.toggleTierEditor = this.toggleTierEditor.bind(this);

    this.toggleCompanyPicker = () => this.setState({companyPickerActive: !this.state.companyPickerActive});

    this.onPickerSave = (companyIDs) => {
      this.saveCompanies(companyIDs)
        .then(() => {
          this.setState({loaded: false}, () => {
            this.getTiers()
            .then(() => {
              this.setState({loaded: true});
            })
            .catch((error) => {
              this.setState({error: "Eroare la interogarea sistemului!"});
            });
          });
        })
        .catch((error) => console.log(error));
    };

    this.onSetDefaultClick = () => {
      this.setDefault()
      .then(() => {
        this.props.onRefreshTiers();
      })
      .catch((error) => console.log(error));
    };
  }

  async setDefault() { 
    await axios.get("discount_group/set_default", {
      params: {
        discount_group_id: this.props.discountGroupID
      }
    });
  }

  async saveCompanies(companyIDs) {
    await axios.post("discount_group/update_companies",
      companyIDs,
      {
        params: {
          discount_group_id: this.props.discountGroupID
        }
      }
    );
  }

  async getTiers() {
    let tierResponse = await axios.get("discount_group/get", {
      params: {
        company_id: global.appConfig.companyID,
        discount_group_id: this.props.discountGroupID
      }
    });

    let tiers = [];
    let lastID = 0;

    let tierData = tierResponse.data.discount_group_info.tiers;
    tierData.forEach((tier) => {
      lastID = lastID + 1;

      tiers.push({
        id: lastID,
        minSales: tier.sales_min,
        name: tier.name,
        discounts: {
          category1: tier.category_1_discount,
          category2: tier.category_2_discount,
          category3: tier.category_3_discount
        },
        discountsPB: {
          category1: tier.category_1_discount_pb,
          category2: tier.category_2_discount_pb,
          category3: tier.category_3_discount_pb
        }
      });
    });

    this.setState({
      tiers: tiers,
      name: tierResponse.data.discount_group_info.name,
      isDefault: tierResponse.data.discount_group_info.is_default == 1, 
      companyIDs: tierResponse.data.discount_group_info.companies,
    });
  }

  componentDidMount() {
    this.getTiers()
      .then(() => {
        this.setState({loaded: true});
      })
      .catch((error) => {
        this.setState({error: "Eroare la interogarea sistemului!"});
      });
  }

  toggleTierEditor() {
    const newState = !this.state.editorActive;
    if(newState === false) {
      this.getTiers();
    }

    this.setState({editorActive: newState});
  }

  render() {
    if (!this.state.loaded) {
      return (
        <ListGroupItem>
          <ListGroupItemHeading>Încărcare...</ListGroupItemHeading>
          <ListGroupItemText>
            <span>Se obțin informațiile din baza de date</span>
          </ListGroupItemText>
        </ListGroupItem>

      );
    }

    let settings;
    let companiesInfo;

    if (this.state.isDefault) {
      companiesInfo = (
        <Alert color="info" fade={false}>
          Acest program de fidelitate este implicit pentru compania dumneavoastră.
          Așadar, toți partenerii neîncadrați in alte programe vor fi încadrați
          automat în acest program.
        </Alert>
      );

      settings = (
        <div className="d-flex justify-content-start">
          {/*<Button className="mr-2">Dezactivare program implicit</Button>*/}
          <Button color="primary" className="mr-2" onClick={this.toggleTierEditor}>Editează reduceri</Button>
        </div>
      );
    } else {
      companiesInfo = (
        /*
        <div className="mb-2">
          <Badge color="primary" className="mr-2">S.C. Vasile Auto SRL</Badge>
          <Badge color="primary" className="mr-2">S.C. Gheorghe Auto SRL</Badge>
          <Badge color="primary" className="mr-2">S.C. Fănel Auto SRL</Badge>
          <Badge color="primary" className="mr-2">S.C. Georgel Auto SRL</Badge>
          <Badge color="secondary" pill>+ încă 10</Badge>
        </div>*/
        <p>
          În acest program de fidelizare sunt incluse <strong>{this.state.companyIDs.length}</strong> companii. Apasă butonul Gestionează companii
          pentru a adăuga sau șterge companii.
        </p>
      );

      settings = (
        <div className="d-flex justify-content-start">
          <Button color="success" className="mr-2" onClick={this.onSetDefaultClick}>Setează drept implicit</Button>

          <Button className="mr-2" onClick={this.toggleCompanyPicker}>Gestionează companii</Button>
          <CompanyMultiPicker defaultCompanies={this.state.companyIDs} isOpen={this.state.companyPickerActive} toggle={this.toggleCompanyPicker} onSave={this.onPickerSave}/>

          <Button color="primary" className="mr-2" onClick={this.toggleTierEditor}>Editează prețuri</Button>
        </div>
      );
    }

    return (
      <ListGroupItem className="mb-2">
        <ListGroupItemHeading>Programul de fidelizare "<strong>{this.state.name}</strong>"</ListGroupItemHeading>
        <ListGroupItemText>
          {
            (this.state.tiers.length === 0) ? (
              <Alert color="info">
                Nu este setat niciun plafon pentru acest program de fidelizare. Pentru a introduce plafoane noi,
                apăsați butonul <i>Editează prețuri</i> de mai jos.
              </Alert>
            ) : (
              <div>
                <p>Partenerii din acest program beneficiază de prețuri conform următorului tabel:</p>
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th>Cuantum comenzi</th>
                      <th>Denumire</th>
                      <th>Preț cat. I</th>
                      <th>Preț cat. II</th>
                      <th>Preț cat. III</th>
                      <th>Preț PB cat. I</th>
                      <th>Preț PB cat. II</th>
                      <th>Preț PB cat. III</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.tiers.map((tier, id) => (
                      <tr>
                        <th scope="row">≥ {tier.minSales} RON</th>
                        <td>{tier.name}</td>
                        {tier.discounts.category1 ? <td>{tier.discounts.category1}%</td> : <td><small>(Nicio reducere)</small></td> }
                        {tier.discounts.category2 ? <td>{tier.discounts.category2}%</td> : <td><small>(Nicio reducere)</small></td> }
                        {tier.discounts.category3 ? <td>{tier.discounts.category3}%</td> : <td><small>(Nicio reducere)</small></td> }
                        {tier.discountsPB.category1 ? <td>{tier.discountsPB.category1}%</td> : <td><small>(Nicio reducere)</small></td> }
                        {tier.discountsPB.category2 ? <td>{tier.discountsPB.category2}%</td> : <td><small>(Nicio reducere)</small></td> }
                        {tier.discountsPB.category3 ? <td>{tier.discountsPB.category3}%</td> : <td><small>(Nicio reducere)</small></td> }
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )
          }

          <h5>Parteneri în acest program de fidelizare:</h5>
          {companiesInfo}

          <h5>Setări</h5>
          <p>Mai jos puteți efectua modificări la acest program de fidelizare:</p>
          {settings}
        </ListGroupItemText>
        
        { this.state.editorActive && (<TierEditor discountGroupID={this.props.discountGroupID} onClose={this.toggleTierEditor} />) }
      </ListGroupItem>
    );
  }
}