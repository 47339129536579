import React, { Component } from "react";
import { Table, Button, ButtonGroup } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class HomePage extends Component {
  render() {
    let employees = [
      {
        id: "1",
        name: "Tiberiu Danciu",
        phoneNumber: "0712345678",
        email: "tiberiu@tiberiud.ro",
        rank: "Administrator"
      },
      {
        id: "24",
        name: "Vasile George",
        phoneNumber: "0712345678",
        email: "vasilegeorge@gmail.com",
        rank: "Operator"
      },
      {
        id: "18",
        name: "Fane Ionescu",
        phoneNumber: "0712345678",
        email: "fane.ionescu@yahoo.com",
        rank: "Angajat"
      },
    ];

    return (
      <div>
        <h1>Angajați</h1>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Nume</th>
              <th>Email</th>
              <th>Telefon</th>
              <th>Funcție</th>
              <th>Acțiuni</th>
            </tr>
          </thead>
          <tbody>
            {
              employees.map((employee, i) => (
                <tr key={i}>
                  <th scope="row">{i+1}</th>
                  <td>{employee.name}</td>
                  <td>{employee.email}</td>
                  <td>{employee.phoneNumber}</td>
                  <td>{employee.rank}</td>
                  <td>
                    <ButtonGroup>
                    <Button color="warning">Schimbă funcția</Button>
                    <Button color="danger"><FontAwesomeIcon icon="trash-alt" /></Button>
                    </ButtonGroup>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
    );
  }
}
