import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Row, Col, Badge, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axios from 'axios';
import appContext from "../../context/appContext";
import OrderSummary from "../../components/orderSummary";
import AddressViewer from "../../components/addressViewer";

class OrderPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderID: props.match.params.orderID,
      productData: null,
      orderData: null,
      loaded: false,
    };
  }

  async getInfo() {
    let {orderID} = this.state;

    let orderInfo = await axios.get('order/get_order', {
      params: {
        order_id: orderID,
      }
    });

    let orderData = orderInfo.data.info;
    let statusHistory = orderInfo.data.history;
    let productData = [];

    orderInfo.data.products.forEach(item => {
      const info = item.info;
      const quantity = item.quantity;
      const discounts = item.discounts;

      productData.push({
        id: info.id,
        code: info.code,
        name: info.name,
        price: info.price,
        currency: info.currency,

        quantity: quantity,
        discounts: discounts
      });
    });

    this.setState({
      productData: productData,
      statusHistory: statusHistory,
      orderData: orderData,
    });
  }

  componentDidMount() {
    this.getInfo()
      .then(() => this.setState({loaded: true}))
      .catch((error) => console.log(error));
  }

  Status(orderStatus) {
    if (orderStatus === "PLACED") {
      return <Badge color="info">Neprocesată</Badge>;
    }
    if (orderStatus === "PROCESSING") {
      return <Badge color="warning">În lucru</Badge>;
    }
    if (orderStatus === "EXPEDITED") {
      return <Badge color="success">Expediată</Badge>;
    }
    if (orderStatus === "CANCELLED") {
      return <Badge color="danger">Anulată</Badge>;
    }

    return <Badge>Necunoscută</Badge>;
  }

  render() {
    if(!this.state.loaded) {
      return (
        <div>Încărcare...</div>
      );
    }

    let {orderData} = this.state;

    return (
      <div>
        <h1 className="align-middle d-flex justify-content-between">
        <span>
          <Button color="primary" onClick={this.props.history.goBack}>
            <FontAwesomeIcon icon="long-arrow-alt-left" />
          </Button>
          &nbsp;Comanda #{this.state.orderID} </span>

          {this.Status(this.state.statusHistory[0].status)}
        </h1>
          

        <hr />
        <Row>
          <Col md="6">
            <h3>Adresă de livrare</h3>
            <AddressViewer addressID={orderData.delivery_address_id} />
          </Col>
          <Col md="6">
            <h3>Despre comandă</h3>
            <p>Plasată de: <strong>{orderData.company_name}</strong></p>
            <p>Plasată la <strong>{orderData.date}</strong></p>
            <p>Total: <span style={{color: "red"}}><strong>{parseFloat(orderData.billed_price).toFixed(2)}</strong> RON</span></p>
          </Col>
        </Row>

        <h3 className="mt-2">Produse în comandă</h3>
        <OrderSummary products={this.state.productData} />

        <h3>Istoric comandă</h3>
        <Table>
          <thead>
            <tr>
              <th>Data</th>
              <th>Statut</th>
              <th>Mențiuni</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.statusHistory.map((value, index) => (
                <tr key={index}>
                  <td>{value.date}</td>
                  <td>{this.Status(value.status)}</td>
                  <td>{value.mentions}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
    );
  }
}
OrderPage.contextType = appContext;

export default withRouter(OrderPage);
