import React, { Component } from "react";

import axios from "axios";

import Menu from "./components/menu";
import PrivateRoute from "./components/privateRoute";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import {
  Badge,
} from "reactstrap";


import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLongArrowAltLeft,
  faSearch,
  faPercent,
  faInfoCircle,
  faBuilding,
  faShoppingCart,
  faTrashAlt,
  faSignInAlt,
  faPencilAlt,
  faCircleNotch,
  faEdit,
  faPlus,
  faHandshake,
  faTruckLoading,
  faHome,
  faTruck,
  faMoneyBillWave,
  faAddressCard,
  faCity,
  faRss,
  faIdCard,
  faEnvelope,
  faKey,
  faComment,
  faTrash,
  faSpinner,
  faUsers,
  faSignOutAlt,
  faUser,
  faPaperclip
} from "@fortawesome/free-solid-svg-icons";

import "./App.css";
import pages from "./pages/main";
import appContext from "./context/appContext";

library.add(
  faLongArrowAltLeft,
  faSearch,
  faPercent,
  faInfoCircle,
  faBuilding,
  faShoppingCart,
  faTrashAlt,
  faSignInAlt,
  faPencilAlt,
  faCircleNotch,
  faEdit,
  faPlus,
  faHandshake,
  faTruckLoading,
  faHome,
  faTruck,
  faMoneyBillWave,
  faAddressCard,
  faCity,
  faRss,
  faIdCard,
  faEnvelope,
  faKey,
  faComment,
  faTrash,
  faSpinner,
  faUsers,
  faSignOutAlt,
  faUser,
  faPaperclip,
);

global.appConfig = require("./config").default;

axios.defaults.withCredentials = true;
axios.defaults.baseURL = global.appConfig.endpointURL;
axios.defaults.headers.common['B2B-Seller-ID'] = global.appConfig.companyID;

class App extends Component {
  constructor(props) {
    super(props);

    this.updateBasketCount = count => {
      this.setState({ basketCount: count });
    };

    this.userDataCallback = () => {
      this.refreshAppHandler().catch((error) => {
        console.log(error);
      });
    };

    this.state = {
      appLoaded: false,
      showAnimation: true,
      animationFading: false,
      isAuthed: null,
      userData: null,
      userCompanyData: null,
      userPermissions: null,
      basketCount: null,
      updateBasketCount: this.updateBasketCount,
      refreshApp: this.reloadAppData,
    };
  }

  reloadAppData = () => {
    this.setState({
      appLoaded: false,
      showAnimation: true,
      animationFading: false,
    }, () => {
      this.refreshAppHandler()
        .then(() => {
          this.setState({
            appLoaded: true,
            showAnimation: false,
            animationFading: true,
          });

          setTimeout(() => {
            this.setState({
              appLoaded: true,
              showAnimation: false,
              animationFading: false,
            });
          }, 1000);
        }).catch((error) => {
          alert("Eroare la încărcarea aplicației!?");
          console.log(error);
        });
    });
  }

  async refreshAppHandler() {
    let userAuthResponse = await axios.get("user/logged_in");
    if (userAuthResponse.data.logged_in === false) {
      this.setState({ isAuthed: false });
      return;
    }

    let userInfoResponse = await axios.get("user/info");
    if(userInfoResponse.data.company_id === null) {
      this.setState({
        isAuthed: true,
        userData: userInfoResponse.data,
      });
      return;
    }

    let userPermissionsResponse = await axios.get("permissions/get_company_permissions", {
      params: {
        user_id: userInfoResponse.data.id,
        company_id: global.appConfig.companyID,
      }
    });

    let basketResponse = await axios.get("basket/get_contents");
    let userCompanyResponse = await axios.get("company/info");

    this.setState({
      isAuthed: true,
      userData: userInfoResponse.data,
      userPermissions: userPermissionsResponse.data,
      userCompanyData: userCompanyResponse.data,
      basketCount: basketResponse.data.products.length,
    });
  }

  componentDidMount() {
    this.reloadAppData();
  }

  render() {
    let { isAuthed, showAnimation, animationFading } = this.state;

    return (
      <div>
        {
          (!this.state.appLoaded || showAnimation || animationFading) && (
            <div className={animationFading ? "loading-panel loading-panel-fade-animation" : "loading-panel"}>
              <div className="sk-folding-cube">
                <div className="sk-cube1 sk-cube"></div>
                <div className="sk-cube2 sk-cube"></div>
                <div className="sk-cube4 sk-cube"></div>
                <div className="sk-cube3 sk-cube"></div>
              </div>
            </div>
          )
        }

        {
          (this.state.appLoaded) && (
            <Router>
              <appContext.Provider value={this.state}>
                <div className="App">
      
                  <div className="d-flex flex-column" style={{ minHeight: "100vh", backgroundColor: "#f2f2f2" }}>
                    <Menu userDataCallback={this.userDataCallback} />
      
                    <div className="container mt-3 mb-5">
                      <Switch>
                        <PrivateRoute path="/admin" component={pages.admin.index} RequiresCompany RequiresEither={['COMPANY_ADMIN', 'COMPANY_OPERATOR']} />
                        <PrivateRoute path="/account" component={pages.account.index} />
      
                        <PrivateRoute RequiresCompany exact path="/" component={pages.company.index} />
                        <PrivateRoute RequiresCompany path="/order/:orderID(\d+)" component={pages.company.order} />
                        <PrivateRoute RequiresCompany path="/orders" component={pages.company.orders} />
                        <PrivateRoute
                          RequiresCompany
                          exact
                          path="/search"
                          render={() => (
                            <pages.company.search
                              updateBasketCount={this.updateBasketCount}
                            />
                          )}
                        />
                        <PrivateRoute RequiresCompany exact path="/deals" component={pages.company.deals} />
                        <PrivateRoute RequiresCompany exact path="/info" component={pages.company.info} />
                        <PrivateRoute RequiresCompany path="/basket" component={pages.company.basket} />
                        <PrivateRoute path="/user" component={pages.company.user} />
      
                        <Route
                          exact
                          path="/login"
                          render={(props) => (
                            <pages.company.login
                              authenticated={isAuthed}
                              userDataCallback={this.userDataCallback}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/register"
                          component={pages.company.register}
                        />
      
                      </Switch>
                    </div>
      
                    <div className="pt-3 pb-3 mt-auto" style={{ backgroundColor: "#e6e6e6", boxShadow: "0 0px 20px 0px #3333334f" }}>
                      <div className="container text-muted  d-flex justify-content-between">
                        <div><span>&copy; 2018-2019 Toate drepturile rezervate. PartenerB2B este o soluție <a href="https://www.workleto.com/">Workleto</a>.</span></div>
                        <div><span className="align-middle" style={{fontSize: "0.7em", color: "#a7a7a7", fontWeight: "400"}} color="dark">
                          VERSIUNEA {process.env.REACT_APP_VERSION} 
                        </span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </appContext.Provider>
            </Router>
          )
        }
      </div>
    );
  }
}

export default App;
