import React, { Component } from "react";
import axios from "axios";
import { Form, Field } from 'simple-react-form'
import SimpleReactValidator from 'simple-react-validator';

import {
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Input, Alert,
    FormGroup, Label, Col,
} from "reactstrap";

export default class UserCreator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,
            formState: {
                name: null,
                email: null,
                phone: null,
            },
        }

        this.save = () => {
            if (!this.validator.allValid()) {
                this.validator.showMessages();
                // rerender to show messages for the first time
                this.forceUpdate();

                return;
            }

            this.setState({loading: true});

            this.saveUser()
                .then(() => {
                    if (this.props.onSave) {
                        this.props.onSave();
                    }
                    this.setState({
                        loading: false,
                        formState: {
                            name: null,
                            email: null,
                            phone: null,
                        }
                    }, this.close());
                })
                .catch(error => {
                    console.log(error);
                    this.setState({error: true});
                });
        }

        this.close = () => {
            if (this.props.toggle && this.props.isOpen) {
                this.props.toggle();
            }
        }

        this.validator = new SimpleReactValidator({
            element: (message) => <div className='invalid-feedback' style={{ display: "initial" }}>{message}</div>
        });
    }

    async saveUser() {
        let {formState} = this.state;
        let UserData = new FormData();
        UserData.append("name", formState.name);
        UserData.append("email", formState.email);
        UserData.append("phone", formState.phone);
    
        await axios({
          method: "post",
          url: "user/create",
          data: UserData,
          config: { headers: { "Content-Type": "multipart/form-data" }}
        });
    }

    textInput(props) {
        return (
            <Input defaultValue={props.defaultValue} type={props.contentType} onChange={event => props.onChange(event.target.value)} placeholder={props.placeholder} invalid={props.invalid} id={props.id} />
        );
    }

    checkBoxInput(props) {
        return (
            <Input defaultValue={props.defaultValue} type="checkbox" onChange={event => props.onChange(event.target.checked)} />
        );
    }

    render() {
        return (
            <Modal fade={false} {...this.props}>
                <ModalHeader toggle={this.props.toggle}>Adaugă un utilizator nou</ModalHeader>
                <ModalBody>
                    {this.state.error && <Alert color="danger">A intervenit o eroare la salvarea datelor!</Alert> }
                    <Form state={this.state.formState} onChange={state => this.setState({ formState: state })}>
                        <FormGroup>
                            <Label>Nume utilizator</Label>
                            <Field contentType="text" fieldName="name" type={this.textInput} placeholder="ex. Pandele Ion" defaultValue={this.state.formState.name} />
                            {this.validator.message('name', this.state.formState.name, 'required')}
                        </FormGroup>

                        <FormGroup>
                            <Label>Adresă de e-mail</Label>
                            <Field contentType="email" fieldName="email" type={this.textInput} placeholder="ex. pandele.ion@companie.ro" defaultValue={this.state.formState.email} />
                            {this.validator.message('email', this.state.formState.email, 'required|email')}
                        </FormGroup>

                        <FormGroup>
                            <Label>Număr de telefon</Label>
                            <Field contentType="text" fieldName="phone" type={this.textInput} placeholder="ex. +40123456789" defaultValue={this.state.formState.phone} />
                            {this.validator.message('phone', this.state.formState.phone, 'required|phone')}
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.close}>Închide</Button>
                    <Button color="success" onClick={this.save}>Creează</Button>
                </ModalFooter>
            </Modal >
        )
    }
}