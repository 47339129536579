import React, { Component } from "react";
import AddressPicker from "../../components/addressPicker";

import {Form, Field} from 'simple-react-form'
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';

import {
  Input,
  Alert,
} from "reactstrap";


export default class CompanyInfoPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formState: {
        email: null,
        website: null,
        phone: null,
        fax: null,
        addressID: null,
      },

      isLoading: true,
      success: undefined,
      error: undefined,
    };

    this.validator = new SimpleReactValidator({
      element: (message) => <div className='invalid-feedback' style={{display: "initial"}}>{message}</div>
    });
    
    this.selectAddressID = (addressID) => { 
      let {formState} = this.state;
      formState.addressID = addressID;
      this.setState({formState: formState});
    }

    this.OnSubmit = (event) => {
      event.preventDefault();
      
      if (this.validator.allValid()) {
        this.setState({isLoading: true});

        let {formState} = this.state;
        
        let Data = {
          email: formState.email,
          website: formState.website,
          phone: formState.phone,
          fax: formState.fax,
          address_id: formState.addressID,
        };
        
        axios.post('seller/set_contact_info', Data)
          .then(() => {
            this.setState({
              isLoading: false,
              success: true,
              error: undefined,
            });
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
              success: undefined,
              error: true,
            });
            console.log(error)
          });
      } else {
        this.validator.showMessages();
        // rerender to show messages for the first time
        this.forceUpdate();
      }
    };
  }

  textInput(props) {
    return (
        <Input defaultValue={props.value}  type={props.contentType} onChange={event => props.onChange(event.target.value)} placeholder={props.placeholder} invalid={props.invalid}/>
    );
  }

  async getInfo() {
    let sellerInfo = await axios.get('seller/contact_info');
    let {data} = sellerInfo;

    let formData = {
      email: data.email,
      website: data.website,
      phone: data.phone,
      fax: data.fax,
      addressID: data.address_id,
    };

    this.setState({formState: formData});
  }



  componentDidMount() {
    this.getInfo()
      .then(() => {
        this.setState({isLoading: false});
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    if(this.state.isLoading) {
      return (
        <h1>Încărcare...</h1>
      );
    }

    return (
      <div>
        <h1>Informații de contact</h1>
        <p>Schimbă informațiile de contact ale companiei</p>

        { 
          this.state.success && (
            <Alert color="success">Datele au fost actualizate cu succes!</Alert>
          )
        }

        { 
          this.state.error && (
            <Alert color="danger">Datele nu au putut fi actualizate!</Alert>
          )
        }

        <Form state={this.state.formState} onChange={state => this.setState({formState: state})}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Email</label>
              <Field contentType="text" fieldName="email" type={this.textInput} placeholder="Adresa de email" />
              {this.validator.message('email', this.state.formState.email, 'email')}
            </div>
            <div className="form-group col-md-6">
              <label>Website</label>
              <Field contentType="text" fieldName="website" type={this.textInput} placeholder="Adresa website-ului" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Telefon</label>
              <Field contentType="text" fieldName="phone" type={this.textInput} placeholder="Număr telefon" />
              {this.validator.message('phone', this.state.formState.phone, 'phone')}
            </div>
            <div className="form-group col-md-6">
              <label>Fax</label>
              <Field contentType="text" fieldName="fax" type={this.textInput} placeholder="Număr fax" />
              {this.validator.message('fax', this.state.formState.fax, 'phone')}
            </div>
          </div>
          <div className="form-group">
            <label>Adresă</label>
            <AddressPicker defaultAddressID={this.state.formState.addressID} onSelect={this.selectAddressID} />
          </div>
          <button type="submit" className="btn btn-success" onClick={this.OnSubmit}>Salvează</button>
        </Form>
      </div>
    );
  }
}
