import React, { Component } from "react";
import { Link, Switch, Route } from "react-router-dom";

import {
  Button,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BasketView from './view';
import BasketOrder from './order';

class BasketPage extends Component {
  render() {
    return (
      <div>
        <h1 className="align-middle">
          <Link to="../">
            <Button color="primary">
              <FontAwesomeIcon icon="long-arrow-alt-left" />
            </Button>
          </Link>
          &nbsp;Coșul meu
        </h1>
        <div>
          <Switch>
            <Route exact path="/basket/order" component={BasketOrder} />
            <Route path="/basket" component={BasketView}  />
          </Switch>
        </div>
      </div>
    );
  }
}

export default BasketPage;
