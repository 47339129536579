import React, { Component } from "react";
import { Table } from "reactstrap";

export default class HomePage extends Component {
  render() {
    let searches = [
      {
        name: "Fane Auto SRL",
        query: "#183291",
        count: null,
      },
      {
        name: "George Motors S.A.",
        query: "#123456",
        count: "1 rezultat",
      },
      {
        name: "Tracia Automobile S.A.",
        query: "#6513",
        count: "3 rezultate",
      },
      {
        name: "Fiord Cars S.A.",
        query: "#9651",
        count: "10 rezultate",
      },
    ];
    
    return (
      <div>
        <h1>Ultimele căutări</h1>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Companie</th>
              <th>Termen căutare</th>
              <th>Rezultate</th>
            </tr>
          </thead>
          <tbody>
            {
              searches.map((search, i) => (
                <tr key={i}>
                  <th scope="row">{i+1}</th>
                  <td>{search.name}</td>
                  <td>{search.query}</td>
                  <td>{(search.count) ? search.count : "(Fără rezultate)"}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>

      </div>
    );
  }
}
