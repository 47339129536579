import React, { Component } from "react";
import axios from "axios";

import {
  Alert,
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Badge,
  Input,
  FormGroup, Label, Col, Table
} from "reactstrap";

export default class OfferProductViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      
      products: [],
    };

    this.close = () => {
      if(this.props.toggle) {
        this.props.toggle();
      }
    }
  }

  componentDidMount() {
    axios.get('offer/get_offer_products', {
      params: {
        offer_id: this.props.offerID,
      }
    })
      .then(response => {
        this.setState({
            loading: false,
            products: response.data.products,
        });
      })
      .catch(error => console.log(error));
  }

  render() {
    return (
      <Modal fade={false} isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>Produse în ofertă</ModalHeader>
        {this.state.loading ? (
          <ModalBody>
            <p>Încărcare...</p>
          </ModalBody>
        ) : (
            <div>
              <ModalBody>
                {this.state.products.length == 0 ? (
                    <p>Nu există niciun produs în această ofertă.</p>
                ) : (
                    <Table striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Cod produs</th>
                                <th>Denumire produs</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.products.map((product, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td><Badge color="secondary">#{product.code}</Badge></td>
                                    <td>{product.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.close}>Închide</Button>
              </ModalFooter>
            </div>
          )}
      </Modal>
    )
  }
}