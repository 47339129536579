import React, { Component } from "react";

import axios from "axios";

import {
  Button,
  Table,
  Alert,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Input, InputGroup, InputGroupAddon,
} from "reactstrap";

export default class TierEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tiers: [],
      isOpen: true,
      loaded: false,
      error: null,
      discountGroupName: null,
      lastID: 0,
    }

    // FUCK. THIS. SHIIIIIIIIIIIIIIIIIIIIT!!!!!!!!!!!!!!!!!!!!!
    this.setTierMinSales = this.setTierMinSales.bind(this);
    this.setTierName = this.setTierName.bind(this);
    this.setTierCat = this.setTierCat.bind(this);

    this.toggleModal = this.toggleModal.bind(this);
  }

  async getTiers() {
    let tierResponse = await axios.get("discount_group/get", {
      params: {
        company_id: global.appConfig.companyID,
        discount_group_id: this.props.discountGroupID
      }
    });

    let tiers = [];
    let lastID = this.state.lastID;

    let tierData = tierResponse.data.discount_group_info.tiers;
    tierData.forEach((tier) => {
      lastID = lastID + 1;

      tiers.push({
        id: lastID,
        minSales: tier.sales_min,
        name: tier.name,
        discounts: {
          category1: tier.category_1_discount,
          category2: tier.category_2_discount,
          category3: tier.category_3_discount
        },
        discountsPB: {
          category1: tier.category_1_discount_pb,
          category2: tier.category_2_discount_pb,
          category3: tier.category_3_discount_pb
        },
      });
    });

    this.setState({
      tiers: tiers,
      lastID: lastID,
      discountGroupName: tierResponse.data.discount_group_info.name
    });
  }

  async setTiers() {
    let tierData = this.state.tiers.map((tier) => {
      return {
        name: tier.name,
        sales_min: tier.minSales,
        category_1_discount: tier.discounts.category1,
        category_2_discount: tier.discounts.category2,
        category_3_discount: tier.discounts.category3,
        category_1_discount_pb: tier.discountsPB.category1,
        category_2_discount_pb: tier.discountsPB.category2,
        category_3_discount_pb: tier.discountsPB.category3,
      };
    });

    await axios.post("discount_group/update_tiers",
      tierData,
      {
        params: {
          company_id: global.appConfig.companyID,
          discount_group_id: this.props.discountGroupID
        }
      }
    );
  }

  toggleModal() {
    this.setState({isOpen: !this.state.isOpen});
  }

  componentDidMount() {
    this.getTiers()
      .then(() => {
        this.setState({loaded: true});
      })
      .catch((error) => {
        this.setState({error: "Eroare la interogarea sistemului!"});
      });
  }

  removeTier = (index) => {
    let newArray = this.state.tiers;
    newArray.splice(index, 1);
    this.setState({ tiers: newArray });
  }

  insertTier = (index) => {
    let newArray = this.state.tiers;
    let newID = this.state.lastID + 1;

    newArray.splice(index, 0, {
      id: newID,
      minSales: null,
      name: null,
      discounts: {
        category1: null,
        category2: null,
        category3: null,
      },
      discountsPB: {
        category1: null,
        category2: null,
        category3: null,
      }
    });

    this.setState({
      tiers: newArray,
      lastID: newID,
    });
  }

  save = () => {
    this.setTiers()
      .then(() => {
        this.toggleModal();
      })
      .catch((error) => {
        this.setState({error: "Nu au putut fi salvate datele"});
      });
  }

  // TODO: FIND A FUCKING BETTER WAY TO HANDLE THIS CRAP

  setTierMinSales = (index, value) => {
    let newArray = this.state.tiers;
    newArray[index].minSales = value;
    this.setState({tiers: newArray});
  }
  setTierName = (index, value) => {
    let newArray = this.state.tiers;
    newArray[index].name = value;
    this.setState({tiers: newArray});
  }
  setTierCat = (index, value, categoryID, isPB) => {
    let newArray = this.state.tiers;
    if(!isPB) {
      newArray[index].discounts[`category${categoryID}`] = value;
    } else {
      newArray[index].discountsPB[`category${categoryID}`] = value;
    }
    this.setState({tiers: newArray});
  }

  render() {
    if(this.state.error) {
      return (
        <Modal fade={false} isOpen={this.state.isOpen} toggle={this.toggleModal} onClosed={this.props.onClose} size="lg">
          <ModalHeader toggle={this.toggleModal}>Eroare</ModalHeader>
          <ModalBody>
            <Alert color="danger">{this.state.error}</Alert>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModal}>Închide</Button>
          </ModalFooter>
        </Modal>
      );
    }

    if(!this.state.loaded) {
      return (
        <Modal fade={false} isOpen={this.state.isOpen} toggle={this.toggleModal} onClosed={this.props.onClose} size="lg">
          <ModalHeader toggle={this.toggleModal}>Încărcare</ModalHeader>
          <ModalBody>
            <p>Cerculeț care se învârtește</p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModal}>Anulează</Button>
          </ModalFooter>
        </Modal>
      );
    }

    return (
      <Modal fade={false} isOpen={this.state.isOpen} toggle={this.toggleModal} onClosed={this.props.onClose} size="lg">
        <ModalHeader toggle={this.toggleModal}>Editează "{this.state.discountGroupName}"</ModalHeader>
        <ModalBody>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Cuantum</th>
                <th>Denumire</th>
                <th>Cat. I</th>
                <th>Cat. II</th>
                <th>Cat. III</th>
                <th>PB Cat. I</th>
                <th>PB Cat. II</th>
                <th>PB Cat. III</th>
                <th>Acțiuni</th>
              </tr>
            </thead>
            <tbody>
              {this.state.tiers.map((tier, index) => (
                <tr key={tier.id}>
                  <th scope="row">
                    <InputGroup style={{flexWrap: "initial"}}>
                      <InputGroupAddon addonType="prepend">≥</InputGroupAddon>
                      <Input style={{minWidth: "3em"}} defaultValue={tier.minSales} onChange={ e => { this.setTierMinSales(index, e.target.value); }} />
                    </InputGroup>
                  </th>
                  <td>
                    <Input style={{minWidth: "10em"}} defaultValue={tier.name} onChange={ e => { this.setTierName(index, e.target.value); }} />
                  </td>
                  <td>
                    <InputGroup style={{flexWrap: "initial"}}>
                      <Input style={{minWidth: "3em"}} defaultValue={tier.discounts.category1} onChange={ e => { this.setTierCat(index, e.target.value, 1); }} />
                      <InputGroupAddon addonType="append">%</InputGroupAddon>
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup style={{flexWrap: "initial"}}>
                      <Input style={{minWidth: "3em"}} defaultValue={tier.discounts.category2} onChange={ e => { this.setTierCat(index, e.target.value, 2); }} />
                      <InputGroupAddon addonType="append">%</InputGroupAddon>
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup style={{flexWrap: "initial"}}>
                      <Input style={{minWidth: "3em"}} defaultValue={tier.discounts.category3} onChange={ e => { this.setTierCat(index, e.target.value, 3); }} />
                      <InputGroupAddon addonType="append">%</InputGroupAddon>
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup style={{flexWrap: "initial"}}>
                      <Input style={{minWidth: "3em"}} defaultValue={tier.discountsPB.category1} onChange={ e => { this.setTierCat(index, e.target.value, 1, true); }} />
                      <InputGroupAddon addonType="append">%</InputGroupAddon>
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup style={{flexWrap: "initial"}}>
                      <Input style={{minWidth: "3em"}} defaultValue={tier.discountsPB.category2} onChange={ e => { this.setTierCat(index, e.target.value, 2, true); }} />
                      <InputGroupAddon addonType="append">%</InputGroupAddon>
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup style={{flexWrap: "initial"}}>
                      <Input style={{minWidth: "3em"}} defaultValue={tier.discountsPB.category3} onChange={ e => { this.setTierCat(index, e.target.value, 3, true); }} />
                      <InputGroupAddon addonType="append">%</InputGroupAddon>
                    </InputGroup>
                  </td>
                  <td>
                    <div>
                      <Button color="danger" onClick={this.removeTier.bind(this, index)}>X</Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button color="success" onClick={this.insertTier.bind(this, this.state.tiers.length)}>Inserează plafon</Button>
        </ModalBody>
        <ModalFooter>
        <Button color="secondary" onClick={this.toggleModal}>Anulează</Button>
        <Button color="primary" onClick={this.save} >Salvează</Button>
      </ModalFooter>
    </Modal>



    );
  }
}